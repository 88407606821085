<template>
  <v-row>
    <v-col cols="12" sm="6" md="4">
      <v-select
        v-model="priceList.temp_mapping_value_id"
        :label="$t('GENERAL.LABELS.TEMPERATURE')"
        :items="temps"
        item-text="name"
        item-value="key"
        :disabled="inputsDisabled"
        @change="$emit('temp-change')"
      />
    </v-col>
    <v-col cols="12" sm="6" md="4">
      <v-autocomplete
        v-model="priceList.incoterm_id"
        :label="$t('GENERAL.LABELS.INCOTERMS')"
        :items="incoterms"
        item-text="name"
        item-value="key"
        clearable
        :disabled="inputsDisabled"
        @change="updatePriceListIncoterm($event)"
      />
    </v-col>
    <v-col cols="12" sm="6" md="4">
      <v-autocomplete
        v-model="priceList.incoterm_destination_country_id"
        :label="$t('GENERAL.LABELS.DESTINATION_COUNTRY')"
        :items="countries"
        item-text="name"
        item-value="key"
        :error="priceList.incoterm?.include_transport && !priceList.incoterm_destination_country_id"
        :disabled="inputsDisabled || !priceList.incoterm_id || !priceList.incoterm?.include_transport"
        @change="updatePriceListIncotermDestinationCountry($event)"
      />
    </v-col>
    <template v-for="field in priceListInterestFields">
      <v-col v-if="field.input_type === 'grouped-mapping'" cols="12" sm="6" md="4">
          <v-autocomplete
            chips
            small-chips
            multiple
            v-model="priceListInterestFieldsValues[field.group_field.mapping_slug]"
            :label="field.group_field.label"
            :items="getInterestFieldOptions(field.group_field)"
            item-text="text"
            item-value="value"
            :disabled="inputsDisabled"
            @change="updatePriceListInterests(field.group_field.mapping_slug)"
          >
            <template v-slot:selection="data">
              <v-chip
                small
                v-bind="data.attrs"
                :close="!inputsDisabled"
                @click:close="handlePriceListInterestRemove(data.item.value, field.group_field.mapping_slug)"
              >
                {{ data.item.text }}
              </v-chip>
            </template>
          </v-autocomplete>
      </v-col>
      <v-col cols="12" sm="6" md="4">
        <v-autocomplete
          :chips="field.is_multiple"
          :small-chips="field.is_multiple"
          :multiple="field.is_multiple"
          v-model="priceListInterestFieldsValues[field.mapping_slug]"
          :label="field.label"
          :items="getInterestFieldOptions(field)"
          item-text="text"
          item-value="value"
          clearable
          :disabled="inputsDisabled"
          @change="updatePriceListInterests(field.mapping_slug)"
        >
          <template v-if="field.is_multiple" v-slot:selection="data">
            <v-chip
              small
              v-bind="data.attrs"
              :close="!inputsDisabled"
              @click:close="handlePriceListInterestRemove(data.item.value, field.mapping_slug)"
            >
              {{ data.item.text }}
            </v-chip>
          </template>
        </v-autocomplete>
      </v-col>
    </template>
  </v-row>
</template>

<script>
import { sortBy } from "lodash";
import { SHOW_SNACK } from "@/store/snack.module";
import {
  GET_PRICE_LIST_INTEREST_FIELDS,
  UPDATE_PRICE_LIST_INTERESTS,
  UPDATE_PRICE_LIST_INCOTERM,
  UPDATE_PRICE_LIST_INCOTERM_DESTINATION_COUNTRY
} from "@/store/priceLists.module";

export default {
  props: {
    temps: {
      type: Array,
      default: []
    },

    countries: {
      type: Array,
      default: []
    },

    incoterms: {
      type: Array,
      default: []
    },

    priceList: {
      type: Object,
      required: true
    },

    inputsDisabled: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      priceListInterestFields: [],
      priceListInterestFieldsValues: {}
    };
  },

  mounted() {
    if (this.priceList.id) {
      this.fetchPriceListInterestFields();
    }
  },

  watch: {
    ['priceList.id']() {
      this.fetchPriceListInterestFields();
    },

    ['priceList.interests']() {
      this.updatePriceListInterestFieldsValues(this.priceListInterestFields);
    }
  },

  methods: {
    fetchPriceListInterestFields() {
      this.$store.dispatch(GET_PRICE_LIST_INTEREST_FIELDS)
        .then(response => {
          response.forEach(field => {
            if (field.input_type === 'grouped-mapping') {
              this.priceListInterestFieldsValues[field.group_field.mapping_slug] = [];
            }

            this.priceListInterestFieldsValues[field.mapping_slug] = field.is_multiple ? [] : null;
          });

          this.$nextTick(() => {
            this.updatePriceListInterestFieldsValues(response);

            this.priceListInterestFields = response;
          });
        });
    },

    updatePriceListInterestFieldsValues(interestFields) {
      interestFields.forEach(field => {
        this.updatePriceListInterestFieldValues(field);

        if (field.input_type === 'grouped-mapping') {
          this.updatePriceListInterestFieldValues(field.group_field);
        }
      });
    },

    updatePriceListInterestFieldValues(field) {
      this.clearFieldValues(field);

      field.options.forEach(option => {
        if (this.priceList.interests.includes(option.value)) {
          if (field.is_multiple) {
            this.priceListInterestFieldsValues[field.mapping_slug].push(option.value);
          } else {
            this.priceListInterestFieldsValues[field.mapping_slug] = option.value;
          }
        }
      });
    },

    getInterestFieldOptions(field) {
      let options = field.options;

      // If field is grouped mapping, filter options that have group selected
      if (field.input_type === 'grouped-mapping') {
        options = options.filter(option => {
          return this.priceList.interests.includes(option.group_value);
        });
      }

      return sortBy(options, ['text']);;
    },

    handlePriceListInterestRemove(value, mappingSlug) {
      this.priceListInterestFieldsValues[mappingSlug].forEach((item, index) => {
        if (item === value) {
          this.priceListInterestFieldsValues[mappingSlug].splice(index, 1);
        }
      });

      this.updatePriceListInterests(mappingSlug);
    },

    updatePriceListInterests(editedFieldSlug) {
      this.$emit('set-processing', true);

      let interests = [];

      this.priceListInterestFields.forEach(field => {
        // Remove all selected values from grouped mapping field that their groups are not in selected group field values
        if (field.input_type === 'grouped-mapping' && field.group_field.mapping_slug === editedFieldSlug) {
          this.removeValuesFromGroupedMappingField(field);
        }

        if (field.input_type === 'grouped-mapping') {
          interests = interests.concat(this.priceListInterestFieldsValues[field.group_field.mapping_slug]);
        }

        if (field.is_multiple) {
          interests = interests.concat(this.priceListInterestFieldsValues[field.mapping_slug]);
        } else {
          interests.push(this.priceListInterestFieldsValues[field.mapping_slug]);
        }
      });

      interests = interests.filter(interest => interest !== null);

      let params = {
        id: this.$route.params.id,
        interests: interests
      };

      this.$store
        .dispatch(UPDATE_PRICE_LIST_INTERESTS, params)
        .then(response => {
          this.$emit('price-list-updated', response);

          this.$emit('set-processing', false);
        })
        .catch(error => {
          this.$store.dispatch(SHOW_SNACK, {
            type: 'error',
            message: this.$t('GENERAL.UNEXPECTED_ERROR')
          });
        });
    },

    removeValuesFromGroupedMappingField(field) {
      let fieldValues = [...this.priceListInterestFieldsValues[field.mapping_slug]];

      fieldValues.forEach(item => {
        let option = field.options.find(option => option.value === item);

        if (!this.priceListInterestFieldsValues[field.group_field.mapping_slug].includes(option.group_value)) {
          let index = this.priceListInterestFieldsValues[field.mapping_slug].findIndex(i => i === item);

          this.priceListInterestFieldsValues[field.mapping_slug].splice(index, 1);
        }
      });
    },

    clearFieldValues(field) {
      if (field.is_multiple) {
        this.priceListInterestFieldsValues[field.mapping_slug].length = 0;
      } else {
        this.priceListInterestFieldsValues[field.mapping_slug] = null;
      }
    },

    updatePriceListIncoterm(incotermId) {
      this.$emit('set-processing', true);

      let params = {
        id: this.$route.params.id,
        incoterm_id: incotermId
      };

      this.$store
        .dispatch(UPDATE_PRICE_LIST_INCOTERM, params)
        .then(response => {
          this.$emit('price-list-updated', response);

          this.$emit('set-processing', false);
        })
        .catch(error => {
          this.$store.dispatch(SHOW_SNACK, {
            type: 'error',
            message: this.$t('GENERAL.UNEXPECTED_ERROR')
          });
        });
    },

    updatePriceListIncotermDestinationCountry(countryId) {
      this.$emit('set-processing', true);

      let params = {
        id: this.$route.params.id,
        incoterm_destination_country_id: countryId
      };

      this.$store
        .dispatch(UPDATE_PRICE_LIST_INCOTERM_DESTINATION_COUNTRY, params)
        .then(response => {
          this.$emit('price-list-updated', response);

          this.$emit('set-processing', false);
        })
        .catch(error => {
          this.$store.dispatch(SHOW_SNACK, {
            type: 'error',
            message: this.$t('GENERAL.UNEXPECTED_ERROR')
          });
        });
    }
  }
};
</script>

<style lang="scss" scoped>
  .contacts-found-label {
    font-size: 16px;
    font-weight: bold;
  }

  .contacts-found-list {
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
  }
</style>
