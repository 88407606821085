<template>
  <tr v-show="!item.parent_id || item.visible" @click="$emit('row-clicked', item)" :class="`row-item-key-${item.id}`">
    <td
      v-for="header in headers"
      :class="{
        'text-center': header.value !== 'description',
        'p-0': ['description', 'options'].includes(header.value)
      }"
    >
      <template v-if="header.value === 'selected'">
        <div class="d-flex justify-content-center">
          <v-checkbox
            hide-details
            class="select-checkbox"
            :input-value="item.selected"
            :disabled="inputsDisabled"
            @change="handleItemSelected(item, $event)"
            @click="$event.stopPropagation()"
          />
        </div>
      </template>
      <span v-else-if="header.value === 'description'" :style="`padding-left: ${(item.level || 0) * 10}px`">
        <v-icon
          v-if="item.has_children"
          @click="
            $event.stopPropagation();
            item.is_open ? $emit('close', item) : $emit('open', item)
          "
        >
          {{ item.is_open ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
        </v-icon>
        <v-icon v-else>
          mdi-minus
        </v-icon>
        {{ handleDisplayValue(header.value, item) }} <span v-if="item.is_copy" class="text-warning">({{ $t('GENERAL.LABELS.COPY') }})</span>
      </span>
      <template v-else-if="header.value === 'client_price'">
        <span
          v-if="
            priceList.type?.slug === 'offer-to-a-contact-or-a-group-of-contacts' &&
            priceList.include_transport_cost &&
            priceList.destination_country_id &&
            !item[header.value]
          "
          :class="{ 'text-danger': priceList?.status?.slug !== 'published' }"
        >
          ---
        </span>
        <b v-else-if="item.is_client_price_mannually_edited">{{ handleDisplayValue(header.value, item) }}</b>
        <span v-else>{{ handleDisplayValue(header.value, item) }}</span>
      </template>
      <template v-else-if="header.value === 'transport_cost'">
        <EditableTableItemInput
          v-if="priceList?.status?.slug !== 'published'"
          input-type="number"
          :value="item[header.value]"
          :min-value="0"
          @item-updated="$emit('transport-cost-updated', $event)"
        >
          <div v-show="item[header.value]">
            <v-tooltip top :color="item.is_transport_data_outdated && !item.is_custom_transport_cost ? 'warning' : 'primary'">
              <template v-slot:activator="{ on, attrs }">
                <span
                  v-bind="attrs"
                  v-on="on"
                >
                  <b v-if="item.is_custom_transport_cost">
                    {{ handleDisplayValue(header.value, item) }}
                  </b>
                  <span
                    v-else
                    :class="{
                      'text-warning': (item.is_transport_data_outdated && !item.is_custom_transport_cost)
                    }"
                  >
                  {{ handleDisplayValue(header.value, item) }}
                  </span>
                </span>
              </template>
              <span>
                <template v-if="item.is_custom_transport_cost">{{ $t('GENERAL.LABELS.CUSTOM_TRANSPORT_COST') }}</template>
                <template v-else-if="item.loading_country_id === priceList.destination_country_id">
                  {{ $t('GENERAL.LABELS.SAME_COUNTRIES') }}
                </template>
                <template v-else>
                  {{ `${$t('GENERAL.LABELS.LAST_UPDATED')}: ${item.last_transport_data_update}` }}
                </template>
              </span>
            </v-tooltip>
            <v-tooltip v-if="item.is_custom_transport_cost" top color="blue">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  x-small
                  color="blue"
                  class="ml-1"
                  v-bind="attrs"
                  v-on="on"
                  @click="
                    $event.stopPropagation();
                    $emit('transport-cost-refresh')
                  "
                >
                  fa fa-redo-alt
                </v-icon>
              </template>
              <span>
                {{ $t('GENERAL.LABELS.REFRESH') }}
              </span>
            </v-tooltip>
          </div>






          <template v-if="!item[header.value]">
            <span class="text-danger">---</span>
            <v-tooltip v-if="hasPermissions('transport-data-create') && item.loading_country_id" top color="red">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  small
                  color="red"
                  class="ml-1"
                  v-bind="attrs"
                  v-on="on"
                  @click="
                    $event.stopPropagation();
                    $emit('item-add-transport-data-clicked', item)
                  "
                >
                  fa fa-plus
                </v-icon>
              </template>
              <span>
                {{ $t('GENERAL.LABELS.ADD_TRANSPORT_DATA') }}
              </span>
            </v-tooltip>
          </template>
        </EditableTableItemInput>
        <span v-else>
          <b v-if="item.is_custom_transport_cost">{{ handleDisplayValue(header.value, item) }}</b>
          <span v-else-if="!item[header.value]">---</span>
          <span v-else>{{ handleDisplayValue(header.value, item) }}</span>
        </span>
      </template>
      <template v-else-if="header.value === 'note'">
        <EditableTableItemInput
          v-if="priceList?.status?.slug !== 'published'"
          :value="item[header.value]"
          @item-updated="$emit('note-updated', $event)"
        >
          <template v-if="item[header.value]">
            {{ item[header.value] }}
          </template>
          <div v-else class="note-empty-value"></div>
        </EditableTableItemInput>
        <span v-else>
          {{ item[header.value] }}
        </span>
      </template>
      <template v-else-if="header.value === 'options'">
        <v-tooltip top color="primary">
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              x-small
              color="indigo"
              v-bind="attrs"
              v-on="on"
              @click="
                $event.stopPropagation();
                $emit('copy-clicked', item)
              "
            >
              fa fa-copy
            </v-icon>
          </template>
          <span>
            {{ $t('GENERAL.LABELS.COPY') }}
          </span>
        </v-tooltip>
        <v-tooltip top color="primary">
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              x-small
              class="ml-2"
              color="indigo"
              v-bind="attrs"
              v-on="on"
              @click="
                $event.stopPropagation();
                $emit('row-clicked', item)
              "
            >
              fa fa-edit
            </v-icon>
          </template>
          <span>
            {{ $t('GENERAL.LABELS.OPEN') }}
          </span>
        </v-tooltip>
      </template>
      <template v-else>
        {{ handleDisplayValue(header.value, item) }}
      </template>
    </td>
  </tr>
</template>

<script>
import formatter from "@/common/formatter.service";
import EditableTableItemInput from '@/views/partials/form/EditableTableItemInput.vue';

export default {
  components: {
    EditableTableItemInput,
    formatter
  },

  props: {
    priceList: {
      type: Object,
      required: true
    },

    item: {
      type: Object,
      required: true
    },

    headers: {
      type: Array,
      default: () => []
    },

    inputsDisabled: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      //
    }
  },

  mounted() {
    //
  },

  methods: {
    handleItemSelected(item, value) {
      item.selected = value;

      this.$emit(
        'item-selected-updated',
        { itemId: item.id, value: value }
      );
    },
    handleDisplayValue(itemName, itemObj) {
      return formatter.handleDisplayValue(itemName, itemObj);
    }
  }
}
</script>

<style lang="scss" scoped>
  .note-empty-value {
    width: 100%;
    height: 100%;
  }
</style>