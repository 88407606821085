<template>
  <v-dialog
    v-model="show"
    ref="dialog"
    persistent
    :max-width="maxWidth"
    @keydown.esc="closeDialog"
    @keydown.enter="closeDialog"
    @close="closeDialog"
  >
    <v-card :disabled="isProcessing">
        <v-card-title class="p-3 sticky-header">
          <div class="d-flex justify-content-between w-100 pb-2 pt-2">
            <span>
              {{ $t('GENERAL.LABELS.LIST_OF_THE_CONTACTS') }}
            </span>
            <div>
              <v-tooltip top color="primary">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                    color="indigo"
                    class="mx-1"
                    @click="closeDialog"
                  >
                    mdi-close
                  </v-icon>
                </template>
                <span>
                  {{ $t('GENERAL.LABELS.CLOSE') }}
                </span>
              </v-tooltip>
            </div>
          </div>
        </v-card-title>
        <v-card-text class="p-0">
          <Table
            v-if="headersPrepared"
            :key="tableKey"
            ref="price-lists-contacts-dialog-table"
            name="price-lists-contacts-dialog-table"
            :headers="headers"
            :headers-slots="['selected']"
            :server-data-options="localServerDataOptions"
            :persistent-filters="false"
            dense
            stripped
            fixed-header
            multi-sort
            filterable
          >
            <template v-if="selectable" v-slot:top-header-actions>
              <v-switch
                v-model="localServerDataOptions.params.onlySelected"
                @change="handleOnlySelectedChange"
              />
              <span class="only-selected-label">{{ $t('GENERAL.LABELS.SHOW_ONLY_SELECTED') }}</span>
            </template>
            <template v-slot:header.selected>
              <div class="d-flex justify-content-center">
                <v-checkbox
                  :key="allSelectedKey"
                  hide-details
                  class="select-checkbox"
                  v-model="allSelectedValue"
                  :indeterminate="hasSelected"
                  :disabled="inputsDisabled || isProcessing"
                  @change="handleAllSelectedChange"
                />
              </div>
            </template>
            <template v-slot:item.selected="{ item }">
              <div class="d-flex justify-content-center">
                <v-checkbox
                  hide-details
                  class="select-checkbox"
                  v-model="item.item.selected"
                  :disabled="inputsDisabled || isProcessing"
                  @change="handleContactSelection(item.item.id, $event)"
                />
              </div>
            </template>
          </Table>
        </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import Table from "@/views/partials/v-table/Table.vue";

export default {
  components: {
    Table
  },

  props: {
    value: {
      type: Boolean,
      default: false
    },

    serverDataOptions: {
      type: Object,
      default: () => ({})
    },

    hasSelected: {
      type: Boolean,
      default: false
    },

    selectable: {
      type: Boolean,
      default: true
    },

    inputsDisabled: {
      type: Boolean,
      default: false
    },

    additionalHeaders: {
      type: Array,
      default: () => []
    },

    maxWidth: {
      type: String,
      default: '700px'
    }
  },

  data() {
    return {
      tableKey: 1,
      allSelectedKey: 1,
      allSelectedValue: this.hasSelected,
      show: this.value,
      isProcessing: false,
      headersPrepared: false,
      localServerDataOptions: this.serverDataOptions,
      headers: [
        {
          text: this.$t('GENERAL.LABELS.SELECTED'),
          value: 'selected',
          sortable: false,
          align: 'center',
          padding: false,
        },
        {
          text: this.$t('GENERAL.LABELS.NAME'),
          value: 'name',
          align: 'center'
        },
        {
          text: this.$t('GENERAL.LABELS.EMAIL'),
          value: 'email',
          align: 'center'
        },
        {
          text: this.$t('GENERAL.LABELS.COMPANY'),
          value: 'company_name',
          align: 'center'
        }
      ]
    };
  },

  mounted() {
    this.prepareHeaders();

    if (this.selectable) {
      this.localServerDataOptions.params.onlySelected = false;
    }
  },

  watch: {
    value() {
      this.show = this.value;

      if (this.show) {
        this.tableKey++;
      }
    },

    hasSelected() {
      this.allSelectedValue = this.hasSelected;
      this.allSelectedKey++;
    },

    serverDataOptions: {
      handler() {
        this.localServerDataOptions = this.serverDataOptions;
      },
      deep: true
    },
  },

  methods: {
    prepareHeaders() {
      if (!this.selectable) {
        this.headers.shift();
      }

      if (this.additionalHeaders.length) {
        this.headers = this.headers.concat(this.additionalHeaders);
      }

      this.headersPrepared = true;
    },
    handleContactSelection(contactId, event) {
      this.$emit('contact-selection', contactId, event);
    },

    handleAllSelectedChange(event) {
      this.$refs['price-lists-contacts-dialog-table'].serverItems.map(item => {
        item.selected = event;
      });

      this.$emit('all-selected-change', event);
    },

    handleOnlySelectedChange(event) {
      this.localServerDataOptions.params.onlySelected = event;
      this.$refs['price-lists-contacts-dialog-table'].fetchServerData();
    },

    closeDialog() {
      this.$emit('dialog-close');
    }
  }
};
</script>

<style lang="scss" scoped>
  .only-selected-label {
    font-size: 14px;
  }
</style>
