<template>
  <v-card flat :disabled="isTableItemsProcessing" class="p-0">
    <v-card-text class="p-0">
      <Table
        v-if="headers"
        ref="price-list-details-table"
        :key="tableKey"
        :name="tableName"
        :headers="headers"
        :headers-slots="['selected']"
        :hidden-headers="hiddenHeaders"
        :items="tableItems"
        :total-items="items.length"
        :filters="filters"
        :loading="isTableItemsProcessing"
        :options.sync="tableOptions"
        dense
        fixed-header
        multi-sort
        filterable
        @search="handleSearch"
        @filters-change="handleFiltersChange"
      >
        <template v-slot:top-header-actions>
          <v-select
            v-model="priceList.temp_mapping_value_id"
            dense
            hide-details
            single-line
            class="top-header-select temperature-select mx-2 my-3"
            :items="temps"
            :disabled="inputsDisabled"
            item-text="name"
            item-value="key"
            :label="$t('GENERAL.LABELS.TEMPERATURE')"
            @change="$emit('temp-change', $event)"
          />
          <v-select
            v-if="priceList.status?.slug !== 'published'"
            v-model="priceList.currency.id"
            dense
            hide-details
            single-line
            class="top-header-select currency-select mx-2 my-3"
            :items="currencies"
            :disabled="inputsDisabled"
            item-text="name"
            item-value="key"
            :label="$t('GENERAL.LABELS.CURRENCY')"
            @change="$emit('currency-change', $event)"
          />
          <span v-else class="ml-2 mr-3 current-currency">{{
            `${priceList.currency.code}
            ${priceList.published_currency_rate && priceList.published_currency_rate !== 1 ?
              ' (' + priceList.published_currency_rate + ')' :
              '' }`
          }}</span>
          <template
            v-if="priceList.type.slug === 'offer-to-a-contact-or-a-group-of-contacts'"
          >
            <v-switch
              v-model="priceList.include_transport_cost"
              class="group-of-contacts-switch"
              :disabled="inputsDisabled"
              @change="$emit('include-transport-cost-change', $event)"
            />
            <span class="include-transport-cost-label">{{ $t('PRICE_LISTS.INCLUDE_TRANSPORT_COST') }}</span>
            <v-select
              v-model="priceList.destination_country_id"
              dense
              hide-details
              single-line
              class="top-header-select temperature-select mx-2 my-3"
              :items="countries"
              :disabled="inputsDisabled || !priceList.include_transport_cost"
              item-text="name"
              item-value="key"
              :label="$t('GENERAL.LABELS.COUNTRY')"
              @change="$emit('destination-country-change', $event)"
            />
          </template>
          <v-spacer />
          <v-tooltip top color="primary">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                color="indigo"
                v-bind="attrs"
                v-on="on"
                @click="openCloseAllItems"
              >
                <v-icon>
                  {{ hasClosedItems ? 'mdi-unfold-more-horizontal' : 'mdi-unfold-less-horizontal' }}
                </v-icon>
              </v-btn>
            </template>
            <span>
              {{ hasClosedItems ? $t('GENERAL.LABELS.EXPAND_ALL') : $t('GENERAL.LABELS.COLLAPSE_ALL') }}
            </span>
          </v-tooltip>
        </template>
        <template v-slot:header.selected>
          <div class="d-flex justify-content-center">
            <v-checkbox
              :key="allSelectedKey"
              hide-details
              class="select-checkbox"
              v-model="allSelectedValue"
              :indeterminate="hasSelectedItems"
              :disabled="inputsDisabled"
              @change="handleAllSelectedChange"
            />
          </div>
        </template>
        <template v-slot:item="{ item }">
          <PriceListTableRow
            :key="item.item.id"
            :price-list="priceList"
            :item="item.item"
            :headers="item.headers"
            :inputsDisabled="inputsDisabled"
            @open="openItem"
            @close="closeItem"
            @row-clicked="handleRowClicked"
            @copy-clicked="handleCopyClicked"
            @item-selected-updated="handleItemSelectedUpdated"
            @item-add-transport-data-clicked="handleItemAddTransportDataClicked"
            @transport-cost-updated="handleTransportCostUpdated(item.item, $event)"
            @transport-cost-refresh="handleTransportCostRefresh(item.item)"
            @note-updated="handleNoteUpdated(item.item.id, $event)"
          />
        </template>
      </Table>
    </v-card-text>
    <PriceListItemDialog
      ref="priceListItemDialog"
      v-model="showDialog"
      :item="selectedItem"
      :price-list="priceList"
      :inputsDisabled="inputsDisabled"
      @client-price-change="handleClientPriceChange"
      @lead-offer-change="handleLeadOfferChange"
      @dialog-close="handleItemDialogClose"
    />
    <AddTransportDataDialog
      v-model="transportDataDialog"
      :transportData="addTransportDataModel"
      :countries="countries"
      :country-fields-disabled="true"
      @transport-data-stored="handleTransportDataStored"
    />
  </v-card>
</template>

<script>
import { orderBy, map } from "lodash";
import { SHOW_SNACK } from "@/store/snack.module";
import Table from "@/views/partials/v-table/Table.vue";
import userStateService from "@/common/userState.service.js";
import expandableTableRowsMixin from "@/common/mixins/expandableTableRows.mixin.js";
import PriceListTableRow from "@/views/pages/priceLists/partials/PriceListTableRow.vue";
import PriceListItemDialog from "@/views/pages/priceLists/partials/PriceListItemDialog.vue";
import AddTransportDataDialog from "@/views/pages/transportData/partials/AddTransportDataDialog.vue";
import {
  GET_PRICE_LIST_POSITIONS_TABLE_COLUMNS,
  GET_PRICE_LIST_POSITIONS_TABLE_FILTERS,
  GET_PRICE_LIST_POSITIONS,
  UPDATE_PRICE_LIST_POSITION_SELECTION,
  UPDATE_PRICE_LIST_POSITIONS_SELECTION,
  UPDATE_PRICE_LIST_POSITION_TRANSPORT_COST,
  REFRESH_PRICE_LIST_POSITION_TRANSPORT_COST,
  COPY_PRICE_LIST_POSITION,
  UPDATE_PRICE_LIST_POSITION_NOTE
} from "@/store/priceLists.module";

export default {
  components: {
    Table,
    PriceListTableRow,
    PriceListItemDialog,
    AddTransportDataDialog
  },

  mixins: [
    expandableTableRowsMixin
  ],

  props: {
    temps: {
      type: Array,
      default: []
    },

    currencies: {
      type: Array,
      default: []
    },

    countries: {
      type: Array,
      default: []
    },

    priceList: {
      type: Object,
      required: true
    },

    inputsDisabled: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      tableKey: 1,
      tableName: 'price-list-positions-table',
      openedItems: {},
      headers: undefined,
      hiddenHeaders: [],
      filters: [],
      filtersValues: {},
      searchString: '',
      //Items that comes from the backend
      items: [],
      //Items that are displayed in the table
      tableItems: undefined,
      isTableItemsProcessing: false,
      selectedItem: undefined,
      showDialog: false,
      allSelectedValue: false,
      allSelectedKey: 1,
      hasSelectedItems: false,
      transportDataStoreItem: undefined,
      addTransportDataModel: {
        id: null,
        country_a_id: null,
        country_b_id: null,
        distance: null,
        price: null
      },
      transportDataDialog: false,
      tableOptions: {
        sortBy: [],
        sortDesc: [false]
      }
    };
  },

  watch: {
    tableOptions: {
      handler () {
        this.handleTableOptionsUpdate();
      },
      deep: true
    },

    ['priceList.include_transport_cost'](value) {
      this.handleIncludeTransportCostChange(value);
    },

    ['priceList.destination_country_id'](value) {
      this.handleDestinationCountryChange(value);
    },

    tableItems: {
      handler() {
        this.handleTableItemsUpdate();
      },
      deep: true
    },
  },

  methods: {
    fetchHeaders() {
      this.$store
        .dispatch(GET_PRICE_LIST_POSITIONS_TABLE_COLUMNS)
        .then(response => {
          let headers = [
            {
                text: '',
                value: 'selected',
                sortable: false,
            },
            ...response.visible
          ];

          if (
            this.priceList.type?.slug === 'offer-to-a-contact-or-a-group-of-contacts' &&
            this.priceList.include_transport_cost &&
            this.priceList.destination_country_id
          ) {
            headers.splice(4, 0, {
              text: this.$t("GENERAL.LABELS.TRANSPORT_COST"),
              value: "transport_cost",
              align: "center"
            });
          }

          headers.push({
            text: this.$t("GENERAL.TABLE.OPTIONS_COLUMN"),
            value: "options",
            sortable: false,
            align: "center",
            class: "options-column"
          });

          this.hiddenHeaders = response.hidden;

          this.headers = headers;
        })
        .catch(error => {
          this.$emit('set-processing', true);

          this.$store.dispatch(SHOW_SNACK, {
            type: 'error',
            message: this.$t('GENERAL.UNEXPECTED_ERROR')
          });
        });
    },

    fetchFilters() {
      this.$store
        .dispatch(GET_PRICE_LIST_POSITIONS_TABLE_FILTERS, this.priceList.id)
        .then(response => {
          this.filters = response;
        })
        .catch(error => {
          this.$emit('set-processing', true);

          this.$store.dispatch(SHOW_SNACK, {
            type: 'error',
            message: this.$t('GENERAL.UNEXPECTED_ERROR')
          });
        });
    },

    fetchItems() {
      this.$emit('set-processing', true);
      this.isTableItemsProcessing = true;

      return this.$store
        .dispatch(GET_PRICE_LIST_POSITIONS, this.priceList.id)
        .then(response => {
          this.items = response;

          this.$emit('positions-updated', this.items);

          this.openedItems = userStateService.getState(`table-state.${this.tableName}.openedItems`, {});

          this.tableItems = this.prepareTableItems(this.items);

          this.isTableItemsProcessing = false;
          this.$emit('set-processing', false);
        })
        .catch(error => {
          this.$store.dispatch(SHOW_SNACK, {
            type: 'error',
            message: this.$t('GENERAL.UNEXPECTED_ERROR')
          });
        });
    },

    orderItems(items) {
      let sortBy = this.tableOptions.sortBy.length > 0 ?
        this.tableOptions.sortBy :
        ['meat', 'description'];

      return orderBy(
        items,
        sortBy,
        this.tableOptions.sortDesc.map(sort => sort ? 'desc': 'asc')
      );
    },

    handleIncludeTransportCostChange(value) {
      if (!value) {
        this.removeTransportCostColumn();
      }
    },

    handleDestinationCountryChange(value) {
      if (value) {
        if (!this.headers) {
          return;
        }

        const columnIndex = this.headers.findIndex(header => header.value === 'transport_cost');

        if (columnIndex === -1) {
          this.headers.splice(4, 0, {
            text: this.$t("GENERAL.LABELS.TRANSPORT_COST"),
            value: "transport_cost",
            align: "center"
          });

          this.tableKey++;
        }
      } else {
        this.removeTransportCostColumn();
      }
    },

    removeTransportCostColumn() {
      if (!this.headers) {
        return;
      }

      const columnIndex = this.headers.findIndex(header => header.value === 'transport_cost');

      if (columnIndex !== -1) {
        this.headers.splice(columnIndex, 1);

        this.tableKey++;
      }
    },

    handleTableOptionsUpdate(options) {
      this.tableItems = this.prepareTableItems(this.items);

      this.$refs['price-list-details-table'].$el
        .querySelector('.v-data-table__wrapper').scrollTop = 0;
    },

    handleTableItemsUpdate() {
      this.hasSelectedItems = this.tableItems.some(item => item.selected);
      this.allSelectedValue = this.hasSelectedItems;
      this.allSelectedKey++;
    },

    handleFiltersChange(filters) {
      this.filtersValues = filters;

      this.tableItems = this.prepareTableItems(this.items);
    },

    applyFilters(items) {
      return items.filter(item => {
        return this.isItemApplyFilters(item);
      });
    },

    isItemApplyFilters(item) {
      let isChildApplyFilters = false;

      if (item.children) {
        item.children.forEach(child => {
          if (this.isItemApplyFilters(child)) {
            isChildApplyFilters = true;
          }
        });
      }

      if (isChildApplyFilters) {
        return true;
      }

      for (const [key, value] of Object.entries(item)) {
        if (this.filtersValues[key] && this.filtersValues[key].length) {
          if (!this.filtersValues[key].includes(value)) {
            return false;
          }
        }
      }

      return true;
    },

    handleSearch(searchString) {
      searchString = searchString.length > 2 ? searchString : '';

      if (this.searchString != searchString) {
        this.searchString = searchString;

        this.tableItems = this.prepareTableItems(this.items);
      }
    },

    applySearch(items) {
      return items.filter(item => {
        return this.isItemIncludeSearchString(item);
      });
    },

    isItemIncludeSearchString(item) {
      let isChildIncludeSearchString = false;

      if (item.children) {
        item.children.forEach(child => {
          if (this.isItemIncludeSearchString(child)) {
            isChildIncludeSearchString = true;
          }
        });
      }

      if (isChildIncludeSearchString) {
        return true;
      }

      return Object.values(item).some(value => {
        return (typeof value === 'string' || value instanceof String) &&
          value.toLowerCase().includes(this.searchString.toLowerCase());
      });
    },

    updateItemById(items, id, updateFn) {
      items.map((item) => {
        if (item.id === id) {
          updateFn(item);
        }

        if (item.children && item.children.length) {
          this.updateItemById(item.children, id, updateFn);
        }
      });
    },

    handleItemSelectedUpdated(event) {
      this.$emit('set-processing', true);

      let params = {
        id: this.$route.params.id,
        live_list_position_snapshot_id: event.itemId,
        value: event.value
      };

      this.$store
        .dispatch(UPDATE_PRICE_LIST_POSITION_SELECTION, params)
        .then((response) => {
          this.$emit('set-processing', false);

          let awaitingContactsAttach =
            this.priceList.type.slug === 'weekly-offer' ||
            this.priceList.type.slug === 'push-offer';

          response.updated_positions.forEach((updatedPositionId) => {
            this.updateItemById(this.items, updatedPositionId, (item) => {
              item.contacts_count = null;
              item.awaiting_contacts_attach = awaitingContactsAttach;
              item.selected = event.value;
            });
          });

          this.tableItems = this.prepareTableItems(this.items);

          this.$emit('positions-updated-need-contacts-fetch', this.items);
        })
        .catch(error => {
          this.$store.dispatch(SHOW_SNACK, {
            type: 'error',
            message: this.$t('GENERAL.UNEXPECTED_ERROR')
          });
        });
    },

    handleAllSelectedChange(value) {
      this.$emit('set-processing', true);

      let itemsForUpdate = this.tableItems;
      if (value) {
        itemsForUpdate = this.tableItems.filter(item => {
          return !item.parent_id;
        });
      }

      if (!itemsForUpdate.length) {
        this.$emit('set-processing', false);

        return;
      }

      itemsForUpdate = map(itemsForUpdate, 'id');

      let params = {
        id: this.$route.params.id,
        live_list_position_snapshots: itemsForUpdate,
        value: value
      };

      this.$store
        .dispatch(UPDATE_PRICE_LIST_POSITIONS_SELECTION, params)
        .then((response) => {
          this.$emit('set-processing', false);

          let awaitingContactsAttach =
            this.priceList.type.slug === 'weekly-offer' ||
            this.priceList.type.slug === 'push-offer';

          response.updated_positions.forEach((updatedPositionId) => {
            this.updateItemById(this.items, updatedPositionId, (item) => {
              item.contacts_count = null;
              item.awaiting_contacts_attach = awaitingContactsAttach;
              item.selected = value;
            });
          });

          this.tableItems = this.prepareTableItems(this.items);

          this.$emit('positions-updated-need-contacts-fetch', this.items);
        })
        .catch(error => {
          this.$store.dispatch(SHOW_SNACK, {
            type: 'error',
            message: this.$t('GENERAL.UNEXPECTED_ERROR')
          });
        });
    },

    handleItemAddTransportDataClicked(item) {
      this.transportDataStoreItem = item;

      this.addTransportDataModel['country_a_id'] = item.loading_country_id;
      this.addTransportDataModel['country_b_id'] = this.priceList.destination_country_id;
      this.addTransportDataModel['distance'] = null;
      this.addTransportDataModel['price'] = null;

      this.transportDataDialog = true;
    },

    handleTransportDataStored() {
      this.fetchItems();

      this.transportDataDialog = false;
      this.transportDataStoreItem = null;
    },

    handleTransportCostUpdated(item, value) {
      value = +value;

      if (isNaN(value) || value < 0) {
        return;
      }

      this.$emit('set-processing', true);

      let params = {
        positionId: item.id,
        transportCost: value
      };

      this.$store
        .dispatch(UPDATE_PRICE_LIST_POSITION_TRANSPORT_COST, params)
        .then((response) => {
          this.$emit('set-processing', false);

          this.updateItemById(this.items, item.id, (item) => {
            item.client_price = response.client_price_with_transport;
            item.full_client_price = response.full_client_price_with_transport;
            item.transport_cost = response.transport_cost;
            item.is_custom_transport_cost = true;
          });

          this.tableItems = this.prepareTableItems(this.items);
        })
        .catch(error => {
          this.$store.dispatch(SHOW_SNACK, {
            type: 'error',
            message: this.$t('GENERAL.UNEXPECTED_ERROR')
          });
        });
    },

    handleTransportCostRefresh(item) {
      this.$emit('set-processing', true);

      this.$store
        .dispatch(REFRESH_PRICE_LIST_POSITION_TRANSPORT_COST, item.id)
        .then((response) => {
          this.$emit('set-processing', false);

          this.updateItemById(this.items, item.id, (item) => {
            item.client_price = response.client_price_with_transport;
            item.full_client_price = response.full_client_price_with_transport;
            item.transport_cost = response.transport_cost;
            item.is_custom_transport_cost = false;
          });

          this.tableItems = this.prepareTableItems(this.items);
        })
        .catch(error => {
          this.$store.dispatch(SHOW_SNACK, {
            type: 'error',
            message: this.$t('GENERAL.UNEXPECTED_ERROR')
          });
        });
    },

    handleCopyClicked(item) {
      this.$emit('set-processing', true);

      this.$store
        .dispatch(COPY_PRICE_LIST_POSITION, item.id)
        .then((response) => {
          this.$emit('set-processing', false);

          let newItem = {...item};

          newItem.id = response.id;
          newItem.is_copy = true;
          newItem.selected = false;

          this.addCopiedItemToItems(this.items, item.id, newItem);

          this.tableItems = this.prepareTableItems(this.items);
        })
        .catch(error => {
          this.$store.dispatch(SHOW_SNACK, {
            type: 'error',
            message: this.$t('GENERAL.UNEXPECTED_ERROR')
          });
        });
    },

    addCopiedItemToItems(items, id, newItem) {
      items.forEach((item, index) => {
        if (item.id === id) {
          items.splice(index + 1, 0, newItem);

          return;
        }

        if (item.children && item.children.length) {
          this.addCopiedItemToItems(item.children, id, newItem);
        }
      });
    },

    handleNoteUpdated(itemId, note) {
      this.$emit('set-processing', true);

      let params = {
        priceListId: this.$route.params.id,
        positionId: itemId,
        note: note
      };

      this.$store
        .dispatch(UPDATE_PRICE_LIST_POSITION_NOTE, params)
        .then((response) => {
          this.$emit('set-processing', false);

          this.updateItemById(this.items, itemId, (item) => {
            item.note = note;
          });

          this.tableItems = this.prepareTableItems(this.items);
        })
        .catch(error => {
          this.$store.dispatch(SHOW_SNACK, {
            type: 'error',
            message: this.$t('GENERAL.UNEXPECTED_ERROR')
          });
        });
    },

    handleClientPriceChange(event) {
      this.updateItemById(this.items, event.itemId, (item) => {
        item.client_price = event.clientPrice;
        item.full_client_price = event.fullClientPrice;
        item.is_client_price_mannually_edited = event.isClientPriceMannuallyEdited;
      });

      this.$emit('positions-updated', this.items);

      this.tableItems = this.prepareTableItems(this.items);
    },

    handleLeadOfferChange(event) {
      let awaitingContactsAttach =
        this.priceList.type.slug === 'weekly-offer' ||
        this.priceList.type.slug === 'push-offer';

      this.updateItemById(this.items, event.itemId, (item) => {
        if (item.selected) {
          item.contacts_count = null;
          item.awaiting_contacts_attach = awaitingContactsAttach;
        }

        for (const key in item) {
          if (key != 'id' && event.leadOffer.hasOwnProperty(key)) {
            item[key] = event.leadOffer[key];
          }
        }

        item.client_price = event.clientPrice;
        item.full_client_price = event.fullClientPrice;
        item.transport_cost = event.transportCost;
        item.is_custom_transport_cost = event.isCustomTransportCost;
        item.last_transport_data_update = event.lastTransportDataUpdate;
        item.is_transport_data_outdated = event.isTransportDataOutdated;
      });

      this.tableItems = this.prepareTableItems(this.items);

      this.$emit('positions-updated-need-contacts-fetch', this.items);
    },

    handleRowClicked(item) {
      this.selectedItem = item;
      this.showDialog = true;
    },

    handleItemDialogClose(event) {
      if (event && event.select) {
        this.handleItemSelectedUpdated({ itemId: event.itemId, value: true });
      }

      let row = this.$refs['price-list-details-table'].$el.querySelector(`.row-item-key-${event.itemId}`);
      if (row) {
        row.classList.add('blink-row');
      }
    }
  }
};
</script>

<style lang="scss" scoped>
  .top-header-select {
    font-size: 14px;
  }

  .temperature-select {
    max-width: 100px;
  }

  .currency-select {
    max-width: 150px;
  }

  .current-currency {
    color: #b3b3b3;
  }

  .include-transport-cost-label {
    font-size: 14px;
  }
</style>
