<template>
  <v-dialog
    v-model="show"
    ref="dialog"
    persistent
    max-width="700px"
    @keydown.esc="closeDialog"
    @keydown.enter="closeDialog"
    @close="closeDialog"
  >
    <v-card :disabled="inputsDisabled">
        <v-card-title class="p-3 sticky-header">
          <div class="d-flex justify-content-between w-100 pb-2 pt-2">
            <span>
              {{ $t('GENERAL.LABELS.REFRESH_PRICE_LIST') }}
            </span>
            <div>
              <v-tooltip top color="primary">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                    color="indigo"
                    class="mx-1"
                    @click="closeDialog"
                  >
                    mdi-close
                  </v-icon>
                </template>
                <span>
                  {{ $t('GENERAL.LABELS.CLOSE') }}
                </span>
              </v-tooltip>
            </div>
          </div>
        </v-card-title>
        <div class="p-3">
          <template v-if="hasChanges">
            <template v-if="changes.new_positions?.length">
              <div class="type-heading text-success">{{ $t('GENERAL.LABELS.NEW_POSITIONS') }}:</div>
              <ul>
                <li v-for="position in changes.new_positions">
                  <span>{{ position.name }} - </span>
                  <span>{{ $t('GENERAL.LABELS.COST_BASE') }}: {{ position.cost_base }}</span>
                  <span> - {{ $t('GENERAL.LABELS.LEAD_OFFER') }}:
                    <span
                      class="text-primary set-cursor-pointer"
                      @click="showOffer(position.lead_offer_id)"
                    >
                      <u>{{ position.lead_offer_id }}</u>
                    </span>
                  </span>
                </li>
              </ul>
            </template>
            <template v-if="changes.updated_positions?.length">
              <div class="type-heading text-warning mt-3">{{ $t('GENERAL.LABELS.UPDATED_POSITIONS') }}:</div>
              <ul>
                <li v-for="position in changes.updated_positions">
                  {{ position.name }}
                  <template v-if="position.cost_base">
                     - {{ $t('GENERAL.LABELS.COST_BASE') }}:
                    <span class="text-warning">
                       {{ `${position.cost_base.old_cost_base} --> ${position.cost_base.cost_base}` }}
                    </span>
                  </template>
                  <template v-if="position.lead_offer">
                     - {{ $t('GENERAL.LABELS.LEAD_OFFER') }}:
                    <span
                      class="text-warning set-cursor-pointer text-underlined"
                      @click="showOffer(position.lead_offer.old_lead_offer_id)"
                    >
                      <u>{{ position.lead_offer.old_lead_offer_id }}</u>
                    </span>
                    <span class="text-warning"> --> </span>
                    <span
                      class="text-warning set-cursor-pointer"
                      @click="showOffer(position.lead_offer.lead_offer_id)"
                    >
                      <u>{{ position.lead_offer.lead_offer_id }}</u>
                    </span>
                  </template>
                </li>
              </ul>
            </template>
            <template v-if="changes.deleted_positions?.length">
              <div class="type-heading text-danger mt-3">{{ $t('GENERAL.LABELS.DELETED_POSITIONS') }}:</div>
              <ul>
                <li v-for="position in changes.deleted_positions">
                  {{ position.name }}
                </li>
              </ul>
            </template>
          </template>
          <div v-else>
            {{ $t('GENERAL.LABELS.NO_CHANGES') }}
          </div>
        </div>
        <v-card-actions class="justify-content-end">
          <v-btn
            color="primary"
            text
            :disabled="inputsDisabled"
            @click="closeDialog"
          >
            {{ $t('GENERAL.LABELS.CLOSE') }}
          </v-btn>
          <v-btn
            v-if="hasChanges"
            color="success"
            text
            :disabled="inputsDisabled"
            @click="$emit('refresh-approved')"
          >
            {{ $t('GENERAL.LABELS.APPROVE') }}
          </v-btn>
        </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import TableService from "@/common/table.service";

export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },

    changes: {
      type: Object,
      default: () => ({})
    },

    inputsDisabled: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      show: this.value
    };
  },

  computed: {
    hasChanges() {
      return this.changes.new_positions?.length ||
        this.changes.updated_positions?.length ||
        this.changes.deleted_positions?.length;
    }
  },

  watch: {
    value() {
      this.show = this.value;
    }
  },

  methods: {
    showOffer(offerId) {
      let state = TableService.getState('VueTable_vendorOffersTable_preferences');
      state.meta.search = "";
      state.filterScenarios = [{
          active:true,
          edit:true,
          filters:[{
              component: "enum",
              data: "vendor_offers.id",
              label: "ID",
              mode: null,
              type: "enum",
              value: [offerId],
          }],
          name: `${this.$t('GENERAL.LABELS.OFFER')}: ${offerId}`
      }];
      TableService.setState(state, 'VueTable_vendorOffersTable_preferences')
      let routeData = this.$router.resolve({
        name: "list-offers"
      });
      window.open(routeData.href, "_blank");
    },

    closeDialog() {
      this.$emit('dialog-close');
    }
  }
};
</script>

<style lang="scss" scoped>
.type-heading {
  font-size: 15px;
}
</style>
