<template>
  <v-card>
    <v-row>
      <v-col cols="12" sm="6">
        <v-text-field
          v-model="priceList.name"
          :label="$t('GENERAL.LABELS.NAME')"
          prepend-icon="mdi-text"
          :disabled="inputsDisabled"
          @input="handleNameChange"
        />
      </v-col>
      <v-col cols="12" sm="6">
        <v-select
          v-model="priceList.type.id"
          :disabled="(!$route.params.id && priceList.name.length < 3) || inputsDisabled"
          :label="$t('GENERAL.LABELS.TYPE')"
          prepend-icon="mdi-text"
          :items="priceListTypes"
          item-text="name"
          item-value="key"
          @change="handleTypeChange"
        />
      </v-col>
    </v-row>
    <template v-if="$route.params.id">
      <ContactsForm
        v-if="priceList.type.slug === 'offer-to-a-contact-or-a-group-of-contacts'"
        :temps="temps"
        :countries="countries"
        :incoterms="incoterms"
        :priceList="priceList"
        :inputsDisabled="inputsDisabled"
        @temp-change="$emit('temp-change')"
        @price-list-updated="$emit('price-list-updated', $event)"
        @set-processing="$emit('set-processing', $event)"
      />
      <v-row>
        <v-col cols="12" sm="6" md="4">
          <v-select
            v-model="priceList.date_type.id"
            :label="$t('GENERAL.LABELS.DATE_TYPE')"
            prepend-icon="mdi-calendar"
            :items="priceListDateTypes"
            item-text="name"
            item-value="key"
            :disabled="inputsDisabled"
            @change="handleDateTypeChange"
          />
        </v-col>
        <v-col v-if="priceList.date_type.slug === 'weeks'" cols="12" sm="6" md="4">
          <v-select
            v-model="priceList.week"
            :label="$t('GENERAL.LABELS.WEEK')"
            prepend-icon="mdi-calendar"
            :items="weeks"
            :disabled="inputsDisabled"
            @change="handleWeekChange"
          />
        </v-col>
        <template v-if="priceList.date_type.slug === 'calendar'">
          <v-col cols="12" sm="6" md="4">
            <DatePicker
              :label="$t('GENERAL.LABELS.START_DATE')"
              v-model="priceList.start_date"
              :disabled="inputsDisabled"
              @input="handleStartDateChange"
            />
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <DatePicker
              :label="$t('GENERAL.LABELS.EXPIRATION_DATE')"
              v-model="priceList.end_date"
              :disabled="inputsDisabled"
              @input="handleEndDateChange"
            />
          </v-col>
        </template>
      </v-row>
    </template>
    <Confirm
      v-model="dataLossConfirm"
      :content="$t('PRICE_LISTS.DATA_LOSS_CONFIRM')"
      @confirm="updatePriceListType"
      @decline="
        dataLossConfirm = false;
        priceList.type.id = priceListTypeId;
      "
    />
  </v-card>
</template>

<script>
import { debounce } from "lodash";
import { SHOW_SNACK } from "@/store/snack.module";
import Confirm from "@/views/partials/dialogs/Confirm.vue";
import userStateService from "@/common/userState.service.js";
import DatePicker from "@/views/partials/form/DatePicker.vue";
import ContactsForm from "@/views/pages/priceLists/partials/ContactsForm.vue";
import {
  CREATE_PRICE_LIST,
  UPDATE_PRICE_LIST_NAME,
  UPDATE_PRICE_LIST_TYPE,
  UPDATE_PRICE_LIST_DATE_TYPE,
  UPDATE_PRICE_LIST_WEEK,
  UPDATE_PRICE_LIST_START_DATE,
  UPDATE_PRICE_LIST_END_DATE
} from "@/store/priceLists.module";

export default {
  components: {
    Confirm,
    DatePicker,
    ContactsForm
  },

  props: {
    temps: {
      type: Array,
      default: []
    },

    countries: {
      type: Array,
      default: []
    },

    incoterms: {
      type: Array,
      default: []
    },

    priceList: {
      type: Object,
      required: true
    },

    inputsDisabled: {
      type: Boolean,
      default: false
    },

    priceListTypes: {
      type: Array,
      default: () => []
    },

    priceListDateTypes: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      priceListTypeId: this.priceList.type.id,
      weeks: [],
      dataLossConfirm: false
    };
  },

  mounted() {
    this.prepareWeeks();
  },

  methods: {
    prepareWeeks() {
      let currentYear = new Date().getFullYear();

      for (let i = 1; i <= 52; i++) {
        let week = i < 10 ? `0${i}` : i;

        this.weeks.push(`${currentYear}-${week}`);
      }

      if (this.priceList.week && !this.weeks.includes(this.priceList.week)) {
        this.weeks.push(this.priceList.week);
        this.weeks.sort();
      }
    },

    handleNameChange: debounce(function (name) {
      if (!this.$route.params.id) {
        return;
      }

      let params = {
        id: this.$route.params.id,
        name: name
      };

      this.$store
        .dispatch(UPDATE_PRICE_LIST_NAME, params)
        .catch(error => {
          this.$emit('set-processing', true);

          this.$store.dispatch(SHOW_SNACK, {
            type: 'error',
            message: this.$t('GENERAL.UNEXPECTED_ERROR')
          });
        });
    }, 1500),

    handleTypeChange() {
      if (!this.$route.params.id) {
        this.createPriceList();
      } else {
        this.dataLossConfirm = true;
      }
    },

    createPriceList() {
      this.$emit('set-processing', true);

      let params = {
        name: this.priceList.name,
        type_id: this.priceList.type.id
      };

      this.$store
        .dispatch(CREATE_PRICE_LIST, params)
        .then(response => {
          history.pushState({}, '', `/price-lists/${response.id}`);
          this.$route.params.id = response.id;

          this.$emit('price-list-created', response);

          userStateService.setState(
            `table-state.price-lists-table.blinkRowItemKey`,
            response.id
          );

          this.$emit('set-processing', false);
        })
        .catch(error => {
          this.$store.dispatch(SHOW_SNACK, {
            type: 'error',
            message: this.$t('GENERAL.UNEXPECTED_ERROR')
          });
        });
    },

    updatePriceListType() {
      this.$emit('set-processing', true);

      let params = {
        id: this.$route.params.id,
        type_id: this.priceList.type.id
      };

      this.$store
        .dispatch(UPDATE_PRICE_LIST_TYPE, params)
        .then(response => {
          this.priceListTypeId = this.priceList.type.id;

          this.$emit('price-list-updated', response);

          this.$emit('set-processing', false);
        })
        .catch(error => {
          this.$store.dispatch(SHOW_SNACK, {
            type: 'error',
            message: this.$t('GENERAL.UNEXPECTED_ERROR')
          });
        });
    },

    handleDateTypeChange(dateTypeId) {
      this.$emit('set-processing', true);

      let params = {
        id: this.$route.params.id,
        date_type_id: this.priceList.date_type.id
      };

      this.$store
        .dispatch(UPDATE_PRICE_LIST_DATE_TYPE, params)
        .then(response => {
          this.$emit('price-list-updated', response);

          this.$emit('set-processing', false);
        })
        .catch(error => {
          this.$store.dispatch(SHOW_SNACK, {
            type: 'error',
            message: this.$t('GENERAL.UNEXPECTED_ERROR')
          });
        });
    },

    handleWeekChange() {
      this.$emit('set-processing', true);

      let params = {
        id: this.$route.params.id,
        week: this.priceList.week
      };

      this.$store
        .dispatch(UPDATE_PRICE_LIST_WEEK, params)
        .then(response => {
          this.$emit('price-list-updated', response);

          this.$emit('set-processing', false);
        })
        .catch(error => {
          this.$store.dispatch(SHOW_SNACK, {
            type: 'error',
            message: this.$t('GENERAL.UNEXPECTED_ERROR')
          });
        });
    },

    handleStartDateChange() {
      this.$emit('set-processing', true);

      let params = {
        id: this.$route.params.id,
        start_date: this.priceList.start_date
      };

      this.$store
        .dispatch(UPDATE_PRICE_LIST_START_DATE, params)
        .then(response => {
          this.$emit('price-list-updated', response);

          this.$emit('set-processing', false);
        })
        .catch(error => {
          this.$store.dispatch(SHOW_SNACK, {
            type: 'error',
            message: this.$t('GENERAL.UNEXPECTED_ERROR')
          });
        });
    },

    handleEndDateChange() {
      this.$emit('set-processing', true);

      let params = {
        id: this.$route.params.id,
        end_date: this.priceList.end_date
      };

      this.$store
        .dispatch(UPDATE_PRICE_LIST_END_DATE, params)
        .then(response => {
          this.$emit('price-list-updated', response);

          this.$emit('set-processing', false);
        })
        .catch(error => {
          this.$store.dispatch(SHOW_SNACK, {
            type: 'error',
            message: this.$t('GENERAL.UNEXPECTED_ERROR')
          });
        });
    }
  }
};
</script>

<style lang="scss" scoped>
  //
</style>
