<template>
  <v-card flat class="p-0">
    <v-card-text class="p-0">
      <v-row>
        <v-col sm="10">
          <div class="offer-heading ml-8 mb-5">
            <i class="fa fa-home"></i> {{ priceList.name.toUpperCase() }}
          </div>
          <v-list v-for="(positions, group, index) in previewPositions" :key="group">
            <v-list-item-title class="mb-3">
              <strong class="ml-8">{{ group.toUpperCase() }}</strong>
            </v-list-item-title>
            <v-list-item-title class="pl-0">
              <v-timeline dense>
                <v-timeline-item
                  v-for="position in positions"
                  :key="position.id"
                  :color="'red'"
                  small
                >
                  <div class="pt-1">
                    <div class="font-weight-normal">
                      <strong>
                        {{ position.text }}
                        <template v-if="
                          priceList.type?.slug === 'weekly-offer' ||
                          priceList.type?.slug === 'push-offer'
                        ">
                          <span
                            v-if="!position.awaiting_contacts_attach"
                            class="ml-8 text-primary set-cursor-pointer"
                            @click="handleContactsClick(position.id, position.contacts_count)"
                          >
                            {{ `${position.contacts_count} ${
                              priceList.type?.slug === 'weekly-offer' ?
                                $t('GENERAL.LABELS.PERSONALIZED_WEEKLY_OFFER_CONTACTS') :
                                $t('GENERAL.LABELS.CONTACTS')
                            }` }}
                          </span>
                          <span
                            v-else
                            class="ml-8 text-primary"
                          >
                            {{ `${$t('GENERAL.LABELS.CONTACTS_LOADING')}...` }}
                          </span>
                        </template>
                      </strong>
                    </div>
                  </div>
                </v-timeline-item>
              </v-timeline>
            </v-list-item-title>
          </v-list>
        </v-col>
        <v-col sm="2">
          <v-progress-circular
            v-if="missingTransportDataLoading"
            indeterminate
            color="red"
          />
          <template v-else-if="missingTransportData.length">
            <div class="text-danger">{{ $t('GENERAL.LABELS.MISSING_TRANSPORT_COSTS') }}:</div>
            <div v-for="data in missingTransportData" class="text-danger">{{ `${data.startCountry} - ${data.loadingCountry}` }}</div>
          </template>
        </v-col>
      </v-row>
    </v-card-text>
    <ContactsDialog
      v-if="contactsDialogPositionId"
      v-model="showContactsDialog"
      :server-data-options="{
        method: contactsTableItemsMethod,
        params: {
          priceListId: priceList.id,
          positionId: contactsDialogPositionId
        }
      }"
      :selectable="priceList.type?.slug === 'push-offer'"
      :inputsDisabled="inputsDisabled"
      :hasSelected="contactsDialogPositionContactsCount > 0"
      :additionalHeaders="[
        {
          text: this.$t('GENERAL.LABELS.CLIENT_PRICE'),
          value: 'client_price',
          align: 'center',
          sortable: false
        },
        {
          text: this.$t('GENERAL.LABELS.TRANSPORT_COST'),
          value: 'transport_cost',
          align: 'center',
          sortable: false
        },
        {
          text: this.$t('GENERAL.LABELS.LOADING_COUNTRY'),
          value: 'loading_country_name',
          align: 'center',
          sortable: false
        }
      ]"
      max-width="1000px"
      @contact-selection="handleContactSelection"
      @all-selected-change="handleAllContactsSelectedChange"
      @dialog-close="showContactsDialog = false"
    />
  </v-card>
</template>

<script>
import { SHOW_SNACK } from "@/store/snack.module";
import ContactsDialog from "@/views/pages/priceLists/partials/ContactsDialog.vue";
import {
  GET_PRICE_LIST_CONTACTS_BY_POSITION,
  UPDATE_PRICE_LIST_POSITION_CONTACT_SELECTION,
  UPDATE_PRICE_LIST_POSITION_ALL_CONTACTS_SELECTED,
  GET_PRICE_LIST_MISSING_TRANSPORT_DATA,
  GET_PRICE_LIST_POSITIONS_CONTACTS_COUNT
} from "@/store/priceLists.module";

export default {
  components: {
    ContactsDialog
  },

  props: {
    priceList: {
      type: Object,
      required: true
    },

    priceListPositions: {
      type: Array,
      default: () => []
    },

    inputsDisabled: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      previewPositions: {},
      missingTransportData: [],
      showContactsDialog: false,
      contactsTableItemsMethod: GET_PRICE_LIST_CONTACTS_BY_POSITION,
      contactsDialogPositionId: null,
      contactsDialogPositionContactsCount: 0,
      missingTransportDataLoading: false
    };
  },

  watch: {
    priceListPositions: {
      handler () {
        this.previewPositions = this.preparePreviewPositions(this.priceListPositions);
      },
      deep: true
    },

    priceList: {
      handler () {
        this.previewPositions = this.preparePreviewPositions(this.priceListPositions);
      },
      deep: true
    }
  },

  methods: {
    preparePreviewPositions(positions, previewPositions = {}) {
      const priceListCurrencyRate = this.priceList.status?.slug !== 'published' ?
        this.priceList.currency.rate :
        this.priceList.published_currency_rate || this.priceList.currency.rate;

      positions.forEach(position => {
        if (position.selected && position.full_client_price !== null) {
          if (!previewPositions[position.meat]) {
            previewPositions[position.meat] = [];
          }

          const price = (position.full_client_price * priceListCurrencyRate).toFixed(2);

          const loading = this.priceList.type?.slug === 'offer-to-a-contact-or-a-group-of-contacts' &&
            this.priceList.include_transport_cost &&
            this.priceList.destination_country ?
              'CPT ' + this.priceList.destination_country.code :
              'EXW ' + position.loading;

          previewPositions[position.meat].push({
            id: position.id,
            contacts_count: position.contacts_count,
            awaiting_contacts_attach: position.awaiting_contacts_attach,
            text: position.description +
            (position.note ? `, ${position.note}` : '') +
            (position.packaging ? `, ${position.packaging}` : '') +
            `, ${price} ${this.priceList.currency.symbol}/kg` +
            ` ${loading}`
          });
        }

        if (position.children) {
          this.preparePreviewPositions(position.children, previewPositions);
        }
      });

      return previewPositions;
    },

    fetchContactsCount() {
      if (
        this.priceList.status?.slug === 'published' ||
        (this.priceList.type?.slug !== 'push-offer' && this.priceList.type?.slug !== 'weekly-offer'))
      {
        return;
      }

      this.missingTransportDataLoading = true;

      this.$store.dispatch(GET_PRICE_LIST_POSITIONS_CONTACTS_COUNT, this.priceList.id)
        .then((response) => {
          let hasAwaitingAttachContacts = false;

          response.forEach((position) => {
            this.updateItemById(this.priceListPositions, position.id, (item) => {
              item.contacts_count = position.contacts_count;
              item.awaiting_contacts_attach = position.awaiting_contacts_attach;
            });

            if (position.awaiting_contacts_attach) {
              hasAwaitingAttachContacts = true;
            }
          });

          if (hasAwaitingAttachContacts) {
            setTimeout(() => {
              this.fetchContactsCount();
            }, 2000);
          } else {
            this.fetchMissingTransportData();
          }
        })
        .catch(() => {
          this.$emit('set-processing', true);

          this.$store.dispatch(SHOW_SNACK, {
            type: 'error',
            message: this.$t('GENERAL.UNEXPECTED_ERROR')
          });
        });
    },

    fetchMissingTransportData() {
      if (
        this.priceList.status?.slug === 'published' ||
        (this.priceList.type?.slug !== 'push-offer' && this.priceList.type?.slug !== 'weekly-offer'))
      {
        return;
      }

      this.missingTransportDataLoading = true;

      this.$store.dispatch(GET_PRICE_LIST_MISSING_TRANSPORT_DATA, this.priceList.id)
        .then((response) => {
          this.missingTransportData = response;

          this.missingTransportDataLoading = false;
        })
        .catch(() => {
          this.$emit('set-processing', true);

          this.$store.dispatch(SHOW_SNACK, {
            type: 'error',
            message: this.$t('GENERAL.UNEXPECTED_ERROR')
          });
        });
    },

    handleContactsClick(positionId, contactsCount) {
      this.contactsDialogPositionId = positionId;
      this.contactsDialogPositionContactsCount = contactsCount;
      this.showContactsDialog = true;
    },

    updateItemById(items, id, updateFn) {
      items.map((item) => {
        if (item.id === id) {
          updateFn(item);
        }

        if (item.children && item.children.length) {
          this.updateItemById(item.children, id, updateFn);
        }
      });
    },

    handleContactSelection(contactId, value) {
      this.$emit('set-processing', true);

      this.$store.dispatch(UPDATE_PRICE_LIST_POSITION_CONTACT_SELECTION, {
        priceListId: this.priceList.id,
        positionId: this.contactsDialogPositionId,
        contactId: contactId,
        value: value
      })
        .then((response) => {
          this.contactsDialogPositionContactsCount = response.contacts_count;

          this.updateItemById(this.priceListPositions, this.contactsDialogPositionId, (item) => {
            item.contacts_count = response.contacts_count;
          });

          this.$emit('set-processing', false);
        })
        .catch(() => {
          this.$emit('set-processing', true);

          this.$store.dispatch(SHOW_SNACK, {
            type: 'error',
            message: this.$t('GENERAL.UNEXPECTED_ERROR')
          });
        });
    },

    handleAllContactsSelectedChange() {
      this.$emit('set-processing', true);

      this.$store.dispatch(UPDATE_PRICE_LIST_POSITION_ALL_CONTACTS_SELECTED, {
        priceListId: this.priceList.id,
        positionId: this.contactsDialogPositionId
      })
        .then((response) => {
          this.contactsDialogPositionContactsCount = response.contacts_count;

          this.updateItemById(this.priceListPositions, this.contactsDialogPositionId, (item) => {
            item.contacts_count = response.contacts_count;
          });

          this.$emit('set-processing', false);
        })
        .catch(() => {
          this.$emit('set-processing', true);

          this.$store.dispatch(SHOW_SNACK, {
            type: 'error',
            message: this.$t('GENERAL.UNEXPECTED_ERROR')
          });
        });
    },

    getPrintViewContent() {
      let content = '';

      for (const [group, positions] of Object.entries(this.previewPositions)) {
        content += `<p><strong>${group}</strong></p>`;

        positions.forEach((position) => {
          content += `<p><strong>${position.text}</strong></p>`;
        });
      }

      return content;
    },

    download() {
      var element = document.createElement("a");

      element.setAttribute(
        "href",
        "data:text/plain;charset=utf-8," +
          encodeURIComponent(
            this.getPrintViewContent()
              .replace(/<p><strong>/g, "")
              .replace(/<\/strong><\/p>/g, "\n")
              .replace(/<p>/g,"")
              .replace(/<\/p>/g,"\n")
          )
      );

      element.setAttribute("download", this.priceList.name + ".txt");

      element.style.display = "none";
      document.body.appendChild(element);

      element.click();

      document.body.removeChild(element);
    }
  }
};
</script>

<style lang="scss" scoped>
  .offer-heading {
    font-size: 20px;
    font-weight: bold;
    color: black;
  }
</style>
