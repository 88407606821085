var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{ref:"dialog",attrs:{"persistent":"","max-width":"98vw"},on:{"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"]))return null;return _vm.closeDialog()},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.closeDialog(true)}],"close":function($event){return _vm.closeDialog()}},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('v-card',{attrs:{"disabled":_vm.positionLoading}},[_c('v-card-title',{staticClass:"p-3 sticky-header"},[_c('div',{staticClass:"d-flex justify-content-between w-100 pb-2 pt-2"},[_c('span',[_vm._v(" "+_vm._s(_vm.priceListPosition?.group_mapping_value_name)+" - "+_vm._s(_vm.priceListPosition?.product_mapping_value_name)+" - "+_vm._s(_vm.priceListPosition?.temp_mapping_value_name)+" "),(_vm.hasPermissions('live-list-view-all'))?_c('router-link',{staticClass:"live-list-link ml-4",attrs:{"to":{
              name: 'live-list',
              query: { 'open-position': _vm.priceListPosition?.live_list_position_id }
            },"target":"_blank"}},[_c('v-icon',{staticClass:"mr-1",attrs:{"x-small":"","color":"primary"}},[_vm._v("fa-external-link-alt")]),_vm._v(_vm._s(_vm.$t('GENERAL.LABELS.OPEN_IN_LIVE_LIST'))+" ")],1):_vm._e()],1),_c('div',[_c('v-tooltip',{attrs:{"top":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({staticClass:"mx-1",attrs:{"color":"success"},on:{"click":function($event){return _vm.closeDialog(true)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-check ")])]}}])},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('GENERAL.LABELS.CLOSE_AND_SELECT'))+" ")])]),_c('v-tooltip',{attrs:{"top":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({staticClass:"mx-1",attrs:{"color":"indigo"},on:{"click":function($event){return _vm.closeDialog()}}},'v-icon',attrs,false),on),[_vm._v(" mdi-close ")])]}}])},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('GENERAL.LABELS.CLOSE'))+" ")])])],1)])]),_c('v-card-text',{staticClass:"p-0"},[_c('div',{staticClass:"d-flex flex-wrap p-2"},[_c('v-alert',{staticClass:"live-list-dialog-alert mb-0 mx-1 my-1",attrs:{"dense":"","border":"left","color":"info"}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-coin")]),_vm._v("   "+_vm._s(_vm.$t('PRICE_LISTS.CLIENT_PRICE'))+":   "),(!_vm.inputsDisabled)?_c('strong',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.clientPrice),expression:"clientPrice"}],staticClass:"price-input",attrs:{"type":"text"},domProps:{"value":(_vm.clientPrice)},on:{"input":function($event){if($event.target.composing)return;_vm.clientPrice=$event.target.value}}})]):_c('strong',[_vm._v(_vm._s(_vm.priceListPosition?.client_price))]),(
              _vm.priceListPosition?.is_client_price_mannually_edited &&
              !_vm.inputsDisabled
            )?_c('v-icon',{attrs:{"color":"white"},on:{"click":_vm.handleClientPriceRefresh}},[_vm._v(" mdi-refresh ")]):_vm._e()],1),_c('v-alert',{staticClass:"live-list-dialog-alert mb-0 mx-1 my-1",attrs:{"dense":"","border":"left","type":"warning","icon":"mdi-coins"}},[_vm._v(" "+_vm._s(_vm.$t('PRICE_LISTS.COST_BASE'))+":   "),_c('strong',[_vm._v(_vm._s(_vm.priceListPosition?.cost_base))])]),(
            _vm.priceList.type?.slug === 'offer-to-a-contact-or-a-group-of-contacts' &&
            _vm.priceList.include_transport_cost &&
            _vm.priceList.destination_country_id
          )?_c('v-alert',{staticClass:"live-list-dialog-alert mb-0 mx-1 my-1",attrs:{"dense":"","border":"left","type":"warning","icon":"flaticon-truck"}},[_vm._v(" "+_vm._s(_vm.$t('PRICE_LISTS.TRANSPORT_COST'))+":   "),_c('strong',[_vm._v(_vm._s(_vm.priceListPosition?.transport_cost > 0 ? _vm.priceListPosition?.transport_cost : '-'))])]):_vm._e(),_c('v-alert',{staticClass:"live-list-dialog-alert mb-0 mx-1 my-1",attrs:{"dense":"","border":"left","type":"warning","icon":"mdi-coins"}},[_vm._v(" "+_vm._s(_vm.$t('PRICE_LISTS.MIN_PRICE_OF_RANK'))+" 1:   "),_c('strong',[_vm._v(_vm._s(_vm.priceListPosition?.rank_1_min_price > 0 ? _vm.priceListPosition?.rank_1_min_price : '-'))])]),_c('v-alert',{staticClass:"live-list-dialog-alert mb-0 mx-1 my-1",attrs:{"dense":"","border":"left","type":"warning","icon":"mdi-coins"}},[_vm._v(" "+_vm._s(_vm.$t('PRICE_LISTS.MIN_PRICE_OF_RANK'))+" 2:   "),_c('strong',[_vm._v(_vm._s(_vm.priceListPosition?.rank_2_min_price > 0 ? _vm.priceListPosition?.rank_2_min_price : '-'))])]),_c('v-alert',{staticClass:"live-list-dialog-alert mb-0 mx-1 my-1",attrs:{"dense":"","border":"left","type":"warning","icon":"mdi-coins"}},[_vm._v(" "+_vm._s(_vm.$t('PRICE_LISTS.MAX_PRICE'))+":   "),_c('strong',[_vm._v(_vm._s(_vm.priceListPosition?.max_price > 0 ? _vm.priceListPosition?.max_price : '-'))])]),_c('v-alert',{staticClass:"live-list-dialog-alert mb-0 mx-1 my-1",attrs:{"dense":"","border":"left","type":"warning","icon":"mdi-coins"}},[_vm._v(" "+_vm._s(_vm.$t('PRICE_LISTS.WEIGHTED_AVERAGE_PRICE'))+":   "),_c('strong',[_vm._v(_vm._s(_vm.priceListPosition?.avg_price > 0 ? _vm.priceListPosition?.avg_price : '-'))])])],1),(_vm.headers && _vm.item)?_c('Table',{key:_vm.tableKey,ref:"price-lists-dialog-table",attrs:{"name":"price-lists-dialog-table","headers":_vm.headers,"hidden-headers":_vm.hiddenHeaders,"server-data-options":{
          method: _vm.tableItemsMethod,
          params: {
            positionId: _vm.item?.id
          }
        },"filters":_vm.filters,"dense":"","stripped":"","fixed-header":"","multi-sort":"","filterable":""},scopedSlots:_vm._u([{key:"item.is_lead",fn:function({ item }){return [(!_vm.priceListPosition?.is_copy)?[(item.item.is_lead)?_c('v-icon',{attrs:{"color":"success"}},[_vm._v("mdi-check")]):_c('span')]:_c('div',{staticClass:"d-flex justify-content-center"},[_c('v-checkbox',{staticClass:"select-checkbox",attrs:{"hide-details":"","disabled":item.item.is_lead},on:{"change":function($event){return _vm.handleLeadOfferChange(item.item)}},model:{value:(item.item.is_lead),callback:function ($$v) {_vm.$set(item.item, "is_lead", $$v)},expression:"item.item.is_lead"}})],1)]}},{key:"item.price",fn:function({ item }){return [(item.item.priceData && item.item.priceData.isConverted)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('span',_vm._g(_vm._b({staticClass:"set-cursor-pointer text-warning font-weight-bold"},'span',attrs,false),on),[_vm._v(" "+_vm._s(item.item.price)+" ")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('GENERAL.LABELS.CONVERTED_FROM'))+": "+_vm._s(item.item.priceData.offerCurrency)),_c('br'),_vm._v(" "+_vm._s(_vm.$t('GENERAL.LABELS.EXCHANGE_RATE'))+": "+_vm._s(item.item.priceData.exchangeRate)),_c('br'),_vm._v(" "+_vm._s(_vm.$t('GENERAL.LABELS.UNCONVERTED_PRICE'))+": "+_vm._s(item.item.priceData.baseOfferPrice)+" ")])]):_c('span',[_vm._v(_vm._s(item.item.price))])]}}],null,false,3488878308)}):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }