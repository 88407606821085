var render = function render(){var _vm=this,_c=_vm._self._c;return _c('tr',{directives:[{name:"show",rawName:"v-show",value:(!_vm.item.parent_id || _vm.item.visible),expression:"!item.parent_id || item.visible"}],class:`row-item-key-${_vm.item.id}`,on:{"click":function($event){return _vm.$emit('row-clicked', _vm.item)}}},_vm._l((_vm.headers),function(header){return _c('td',{class:{
      'text-center': header.value !== 'description',
      'p-0': ['description', 'options'].includes(header.value)
    }},[(header.value === 'selected')?[_c('div',{staticClass:"d-flex justify-content-center"},[_c('v-checkbox',{staticClass:"select-checkbox",attrs:{"hide-details":"","input-value":_vm.item.selected,"disabled":_vm.inputsDisabled},on:{"change":function($event){return _vm.handleItemSelected(_vm.item, $event)},"click":function($event){return $event.stopPropagation()}}})],1)]:(header.value === 'description')?_c('span',{style:(`padding-left: ${(_vm.item.level || 0) * 10}px`)},[(_vm.item.has_children)?_c('v-icon',{on:{"click":function($event){$event.stopPropagation();
          _vm.item.is_open ? _vm.$emit('close', _vm.item) : _vm.$emit('open', _vm.item)}}},[_vm._v(" "+_vm._s(_vm.item.is_open ? 'mdi-chevron-up' : 'mdi-chevron-down')+" ")]):_c('v-icon',[_vm._v(" mdi-minus ")]),_vm._v(" "+_vm._s(_vm.handleDisplayValue(header.value, _vm.item))+" "),(_vm.item.is_copy)?_c('span',{staticClass:"text-warning"},[_vm._v("("+_vm._s(_vm.$t('GENERAL.LABELS.COPY'))+")")]):_vm._e()],1):(header.value === 'client_price')?[(
          _vm.priceList.type?.slug === 'offer-to-a-contact-or-a-group-of-contacts' &&
          _vm.priceList.include_transport_cost &&
          _vm.priceList.destination_country_id &&
          !_vm.item[header.value]
        )?_c('span',{class:{ 'text-danger': _vm.priceList?.status?.slug !== 'published' }},[_vm._v(" --- ")]):(_vm.item.is_client_price_mannually_edited)?_c('b',[_vm._v(_vm._s(_vm.handleDisplayValue(header.value, _vm.item)))]):_c('span',[_vm._v(_vm._s(_vm.handleDisplayValue(header.value, _vm.item)))])]:(header.value === 'transport_cost')?[(_vm.priceList?.status?.slug !== 'published')?_c('EditableTableItemInput',{attrs:{"input-type":"number","value":_vm.item[header.value],"min-value":0},on:{"item-updated":function($event){return _vm.$emit('transport-cost-updated', $event)}}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.item[header.value]),expression:"item[header.value]"}]},[_c('v-tooltip',{attrs:{"top":"","color":_vm.item.is_transport_data_outdated && !_vm.item.is_custom_transport_cost ? 'warning' : 'primary'},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[(_vm.item.is_custom_transport_cost)?_c('b',[_vm._v(" "+_vm._s(_vm.handleDisplayValue(header.value, _vm.item))+" ")]):_c('span',{class:{
                    'text-warning': (_vm.item.is_transport_data_outdated && !_vm.item.is_custom_transport_cost)
                  }},[_vm._v(" "+_vm._s(_vm.handleDisplayValue(header.value, _vm.item))+" ")])])]}}],null,true)},[_c('span',[(_vm.item.is_custom_transport_cost)?[_vm._v(_vm._s(_vm.$t('GENERAL.LABELS.CUSTOM_TRANSPORT_COST')))]:(_vm.item.loading_country_id === _vm.priceList.destination_country_id)?[_vm._v(" "+_vm._s(_vm.$t('GENERAL.LABELS.SAME_COUNTRIES'))+" ")]:[_vm._v(" "+_vm._s(`${_vm.$t('GENERAL.LABELS.LAST_UPDATED')}: ${_vm.item.last_transport_data_update}`)+" ")]],2)]),(_vm.item.is_custom_transport_cost)?_c('v-tooltip',{attrs:{"top":"","color":"blue"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-1",attrs:{"x-small":"","color":"blue"},on:{"click":function($event){$event.stopPropagation();
                  _vm.$emit('transport-cost-refresh')}}},'v-icon',attrs,false),on),[_vm._v(" fa fa-redo-alt ")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('GENERAL.LABELS.REFRESH'))+" ")])]):_vm._e()],1),(!_vm.item[header.value])?[_c('span',{staticClass:"text-danger"},[_vm._v("---")]),(_vm.hasPermissions('transport-data-create') && _vm.item.loading_country_id)?_c('v-tooltip',{attrs:{"top":"","color":"red"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-1",attrs:{"small":"","color":"red"},on:{"click":function($event){$event.stopPropagation();
                  _vm.$emit('item-add-transport-data-clicked', _vm.item)}}},'v-icon',attrs,false),on),[_vm._v(" fa fa-plus ")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('GENERAL.LABELS.ADD_TRANSPORT_DATA'))+" ")])]):_vm._e()]:_vm._e()],2):_c('span',[(_vm.item.is_custom_transport_cost)?_c('b',[_vm._v(_vm._s(_vm.handleDisplayValue(header.value, _vm.item)))]):(!_vm.item[header.value])?_c('span',[_vm._v("---")]):_c('span',[_vm._v(_vm._s(_vm.handleDisplayValue(header.value, _vm.item)))])])]:(header.value === 'note')?[(_vm.priceList?.status?.slug !== 'published')?_c('EditableTableItemInput',{attrs:{"value":_vm.item[header.value]},on:{"item-updated":function($event){return _vm.$emit('note-updated', $event)}}},[(_vm.item[header.value])?[_vm._v(" "+_vm._s(_vm.item[header.value])+" ")]:_c('div',{staticClass:"note-empty-value"})],2):_c('span',[_vm._v(" "+_vm._s(_vm.item[header.value])+" ")])]:(header.value === 'options')?[_c('v-tooltip',{attrs:{"top":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({attrs:{"x-small":"","color":"indigo"},on:{"click":function($event){$event.stopPropagation();
              _vm.$emit('copy-clicked', _vm.item)}}},'v-icon',attrs,false),on),[_vm._v(" fa fa-copy ")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('GENERAL.LABELS.COPY'))+" ")])]),_c('v-tooltip',{attrs:{"top":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"x-small":"","color":"indigo"},on:{"click":function($event){$event.stopPropagation();
              _vm.$emit('row-clicked', _vm.item)}}},'v-icon',attrs,false),on),[_vm._v(" fa fa-edit ")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('GENERAL.LABELS.OPEN'))+" ")])])]:[_vm._v(" "+_vm._s(_vm.handleDisplayValue(header.value, _vm.item))+" ")]],2)}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }