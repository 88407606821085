var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"p-0",attrs:{"flat":""}},[_c('v-card-text',{staticClass:"p-0"},[_c('v-row',[_c('v-col',{attrs:{"sm":"10"}},[_c('div',{staticClass:"offer-heading ml-8 mb-5"},[_c('i',{staticClass:"fa fa-home"}),_vm._v(" "+_vm._s(_vm.priceList.name.toUpperCase())+" ")]),_vm._l((_vm.previewPositions),function(positions,group,index){return _c('v-list',{key:group},[_c('v-list-item-title',{staticClass:"mb-3"},[_c('strong',{staticClass:"ml-8"},[_vm._v(_vm._s(group.toUpperCase()))])]),_c('v-list-item-title',{staticClass:"pl-0"},[_c('v-timeline',{attrs:{"dense":""}},_vm._l((positions),function(position){return _c('v-timeline-item',{key:position.id,attrs:{"color":'red',"small":""}},[_c('div',{staticClass:"pt-1"},[_c('div',{staticClass:"font-weight-normal"},[_c('strong',[_vm._v(" "+_vm._s(position.text)+" "),(
                        _vm.priceList.type?.slug === 'weekly-offer' ||
                        _vm.priceList.type?.slug === 'push-offer'
                      )?[(!position.awaiting_contacts_attach)?_c('span',{staticClass:"ml-8 text-primary set-cursor-pointer",on:{"click":function($event){return _vm.handleContactsClick(position.id, position.contacts_count)}}},[_vm._v(" "+_vm._s(`${position.contacts_count} ${ _vm.priceList.type?.slug === 'weekly-offer' ? _vm.$t('GENERAL.LABELS.PERSONALIZED_WEEKLY_OFFER_CONTACTS') : _vm.$t('GENERAL.LABELS.CONTACTS') }`)+" ")]):_c('span',{staticClass:"ml-8 text-primary"},[_vm._v(" "+_vm._s(`${_vm.$t('GENERAL.LABELS.CONTACTS_LOADING')}...`)+" ")])]:_vm._e()],2)])])])}),1)],1)],1)})],2),_c('v-col',{attrs:{"sm":"2"}},[(_vm.missingTransportDataLoading)?_c('v-progress-circular',{attrs:{"indeterminate":"","color":"red"}}):(_vm.missingTransportData.length)?[_c('div',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.$t('GENERAL.LABELS.MISSING_TRANSPORT_COSTS'))+":")]),_vm._l((_vm.missingTransportData),function(data){return _c('div',{staticClass:"text-danger"},[_vm._v(_vm._s(`${data.startCountry} - ${data.loadingCountry}`))])})]:_vm._e()],2)],1)],1),(_vm.contactsDialogPositionId)?_c('ContactsDialog',{attrs:{"server-data-options":{
      method: _vm.contactsTableItemsMethod,
      params: {
        priceListId: _vm.priceList.id,
        positionId: _vm.contactsDialogPositionId
      }
    },"selectable":_vm.priceList.type?.slug === 'push-offer',"inputsDisabled":_vm.inputsDisabled,"hasSelected":_vm.contactsDialogPositionContactsCount > 0,"additionalHeaders":[
      {
        text: this.$t('GENERAL.LABELS.CLIENT_PRICE'),
        value: 'client_price',
        align: 'center',
        sortable: false
      },
      {
        text: this.$t('GENERAL.LABELS.TRANSPORT_COST'),
        value: 'transport_cost',
        align: 'center',
        sortable: false
      },
      {
        text: this.$t('GENERAL.LABELS.LOADING_COUNTRY'),
        value: 'loading_country_name',
        align: 'center',
        sortable: false
      }
    ],"max-width":"1000px"},on:{"contact-selection":_vm.handleContactSelection,"all-selected-change":_vm.handleAllContactsSelectedChange,"dialog-close":function($event){_vm.showContactsDialog = false}},model:{value:(_vm.showContactsDialog),callback:function ($$v) {_vm.showContactsDialog=$$v},expression:"showContactsDialog"}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }