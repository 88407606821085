<template>
  <span>
    <span class="d-flex justify-content-center" v-if="isEditMode">
      <v-text-field
        ref="input"
        solo
        dense
        class="editable-table-item-input"
        :type="inputType"
        v-model="localValue"
        @click.native="$event.stopPropagation()"
        @keydown.esc="handleCancelClick()"
      />
      <v-icon
        color="green"
        class="ml-0 set-cursor-pointer"
        @click.native="
          $event.stopPropagation();
          handleSaveClick();
        "
      >
        mdi-check
      </v-icon>
      <v-icon
        color="red"
        class="ml-0 set-cursor-pointer"
        @click.native="
          $event.stopPropagation();
          handleCancelClick();
        "
      >
        mdi-close
      </v-icon>
    </span>
    <span
      v-else
      class="set-cursor-pointer"
      @click="
        $event.stopPropagation();
        handleItemClick();
      "
    >
      <slot>
        {{ value }}
      </slot>
    </span>
  </span>
</template>

<script>

export default {
  props: {
    value: {
      default: null
    },

    inputType: {
      type: String,
      default: 'text'
    },

    minValue: {
      type: Number,
      default: null
    }
  },

  data() {
    return {
      isEditMode: false,
      localValue: this.value
    }
  },

  watch: {
    value() {
      this.localValue = this.value;
    },

    localValue() {
      if (
        (this.inputType === 'number' && isNaN(this.localValue)) ||
        (this.minValue !== null && this.localValue < this.minValue)
      ) {
        this.localValue = 0;
      }
    }
  },

  methods: {
    handleItemClick() {
      this.isEditMode = true;

      this.$nextTick(() => {
        this.$refs.input.focus();
        this.$nextTick(() => {
          this.$refs.input.$refs.input.select();
        });
      });
    },

    handleSaveClick() {
      this.isEditMode = false;

      this.$emit('item-updated', this.localValue);
    },

    handleCancelClick() {
      this.isEditMode = false;
    }
  }
}
</script>

<style lang="scss" scoped>
:deep(.v-text-field__details) {
  display: none;
}

:deep(.v-input__slot) {
  margin-bottom: 0 !important;
  max-height: 28px !important;
  height: 28px !important;
}

:deep(.v-input__control) {
  min-height: 31px !important;
  max-height: 31px !important;
  height: 31px !important;
}

.editable-table-item-input {
  border: 1px solid #c5c5c5;
  max-width: 100px;
  max-height: 31px;
  height: 31px;
  overflow: hidden;

  :deep(input) {
    padding: 4px 0 !important;
  }

  :deep(input[type=number]) {
    appearance: textfield;
  }

  :deep(input::-webkit-outer-spin-button),
  :deep(input::-webkit-inner-spin-button) {
    -webkit-appearance: none;
    margin: 0;
  }
}


</style>