<template>
  <v-dialog
    v-model="show"
    ref="dialog"
    persistent
    max-width="98vw"
    @keydown.esc="closeDialog()"
    @keydown.enter="closeDialog(true)"
    @close="closeDialog()"
  >
    <v-card :disabled="positionLoading">
      <v-card-title class="p-3 sticky-header">
        <div class="d-flex justify-content-between w-100 pb-2 pt-2">
          <span>
            {{ priceListPosition?.group_mapping_value_name }} -
            {{ priceListPosition?.product_mapping_value_name }} -
            {{ priceListPosition?.temp_mapping_value_name }}

            <router-link
              v-if="hasPermissions('live-list-view-all')"
              :to="{
                name: 'live-list',
                query: { 'open-position': priceListPosition?.live_list_position_id }
              }"
              target="_blank"
              class="live-list-link ml-4"
            >
              <v-icon x-small class="mr-1" color="primary">fa-external-link-alt</v-icon>{{ $t('GENERAL.LABELS.OPEN_IN_LIVE_LIST') }}
            </router-link>
          </span>
          <div>
            <v-tooltip top color="primary">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  color="success"
                  class="mx-1"
                  @click="closeDialog(true)"
                >
                  mdi-check
                </v-icon>
              </template>
              <span>
                {{ $t('GENERAL.LABELS.CLOSE_AND_SELECT') }}
              </span>
            </v-tooltip>
            <v-tooltip top color="primary">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  color="indigo"
                  class="mx-1"
                  @click="closeDialog()"
                >
                  mdi-close
                </v-icon>
              </template>
              <span>
                {{ $t('GENERAL.LABELS.CLOSE') }}
              </span>
            </v-tooltip>
          </div>
        </div>
      </v-card-title>
      <v-card-text class="p-0">
        <div class="d-flex flex-wrap p-2">
          <v-alert
            dense
            border="left"
            color="info"
            class="live-list-dialog-alert mb-0 mx-1 my-1"
          >
            <v-icon color="white">mdi-coin</v-icon>
            &nbsp;
            {{ $t('PRICE_LISTS.CLIENT_PRICE') }}:
            &nbsp;
            <strong v-if="!inputsDisabled">
              <input
                type="text"
                v-model="clientPrice"
                class="price-input"
              />
            </strong>
            <strong v-else>{{ priceListPosition?.client_price }}</strong>
            <v-icon
              v-if="
                priceListPosition?.is_client_price_mannually_edited &&
                !inputsDisabled
              "
              color="white"
              @click="handleClientPriceRefresh"
            >
              mdi-refresh
            </v-icon>
          </v-alert>
          <v-alert
            dense
            border="left"
            type="warning"
            icon="mdi-coins"
            class="live-list-dialog-alert mb-0 mx-1 my-1"
          >
            {{ $t('PRICE_LISTS.COST_BASE') }}:
            &nbsp;
            <strong>{{ priceListPosition?.cost_base }}</strong>
          </v-alert>
          <v-alert
            v-if="
              priceList.type?.slug === 'offer-to-a-contact-or-a-group-of-contacts' &&
              priceList.include_transport_cost &&
              priceList.destination_country_id
            "
            dense
            border="left"
            type="warning"
            icon="flaticon-truck"
            class="live-list-dialog-alert mb-0 mx-1 my-1"
          >
            {{ $t('PRICE_LISTS.TRANSPORT_COST') }}:
            &nbsp;
            <strong>{{ priceListPosition?.transport_cost > 0 ? priceListPosition?.transport_cost : '-' }}</strong>
          </v-alert>
          <v-alert
            dense
            border="left"
            type="warning"
            icon="mdi-coins"
            class="live-list-dialog-alert mb-0 mx-1 my-1"
          >
            {{ $t('PRICE_LISTS.MIN_PRICE_OF_RANK') }} 1:
            &nbsp;
            <strong>{{ priceListPosition?.rank_1_min_price > 0 ? priceListPosition?.rank_1_min_price : '-' }}</strong>
          </v-alert>
          <v-alert
            dense
            border="left"
            type="warning"
            icon="mdi-coins"
            class="live-list-dialog-alert mb-0 mx-1 my-1"
          >
            {{ $t('PRICE_LISTS.MIN_PRICE_OF_RANK') }} 2:
            &nbsp;
            <strong>{{ priceListPosition?.rank_2_min_price > 0 ? priceListPosition?.rank_2_min_price : '-' }}</strong>
          </v-alert>
          <v-alert
            dense
            border="left"
            type="warning"
            icon="mdi-coins"
            class="live-list-dialog-alert mb-0 mx-1 my-1"
          >
            {{ $t('PRICE_LISTS.MAX_PRICE') }}:
            &nbsp;
            <strong>{{ priceListPosition?.max_price > 0 ? priceListPosition?.max_price : '-' }}</strong>
          </v-alert>
          <v-alert
            dense
            border="left"
            type="warning"
            icon="mdi-coins"
            class="live-list-dialog-alert mb-0 mx-1 my-1"
          >
            {{ $t('PRICE_LISTS.WEIGHTED_AVERAGE_PRICE') }}:
            &nbsp;
            <strong>{{ priceListPosition?.avg_price > 0 ? priceListPosition?.avg_price : '-' }}</strong>
          </v-alert>
        </div>
        <Table
          v-if="headers && item"
          ref="price-lists-dialog-table"
          :key="tableKey"
          name="price-lists-dialog-table"
          :headers="headers"
          :hidden-headers="hiddenHeaders"
          :server-data-options="{
            method: tableItemsMethod,
            params: {
              positionId: item?.id
            }
          }"
          :filters="filters"
          dense
          stripped
          fixed-header
          multi-sort
          filterable
        >
          <template v-slot:item.is_lead="{ item }">
            <template v-if="!priceListPosition?.is_copy">
              <v-icon v-if="item.item.is_lead" color="success">mdi-check</v-icon>
              <span v-else></span>
            </template>
            <div v-else class="d-flex justify-content-center">
              <v-checkbox
                hide-details
                class="select-checkbox"
                v-model="item.item.is_lead"
                :disabled="item.item.is_lead"
                @change="handleLeadOfferChange(item.item)"
              />
            </div>
          </template>
          <template v-slot:item.price="{ item }">
            <v-tooltip v-if="item.item.priceData && item.item.priceData.isConverted" bottom>
              <template v-slot:activator="{ on, attrs }">
                <span
                class="set-cursor-pointer text-warning font-weight-bold"
                v-bind="attrs"
                v-on="on"
                >
                  {{ item.item.price }}
                </span>
              </template>
              <span>
                {{ $t('GENERAL.LABELS.CONVERTED_FROM') }}: {{ item.item.priceData.offerCurrency }}<br />
                {{ $t('GENERAL.LABELS.EXCHANGE_RATE') }}: {{ item.item.priceData.exchangeRate }}<br />
                {{ $t('GENERAL.LABELS.UNCONVERTED_PRICE') }}: {{ item.item.priceData.baseOfferPrice }}
              </span>
            </v-tooltip>
            <span v-else>{{ item.item.price }}</span>
          </template>
        </Table>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { debounce } from "lodash";
import { SHOW_SNACK } from "@/store/snack.module";
import Table from "@/views/partials/v-table/Table.vue";
import {
  GET_PRICE_LIST_POSITION,
  GET_PRICE_LIST_POSITION_OFFERS_TABLE_COLUMNS,
  GET_PRICE_LIST_POSITION_OFFERS_TABLE_FILTERS,
  GET_PRICE_LIST_POSITION_OFFERS,
  UPDATE_PRICE_LIST_POSITION_CLIENT_PRICE,
  REFRESH_PRICE_LIST_POSITION_CLIENT_PRICE,
  UPDATE_PRICE_LIST_POSITION_LEAD_OFFER
} from "@/store/priceLists.module";

export default {
  components: {
    Table
  },

  props: {
    value: {
      type: Boolean,
      default: false
    },

    item: {
      type: Object,
      default: null
    },

    priceList: {
      type: Object,
      required: true
    },

    inputsDisabled: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      show: this.value,
      tableKey: 1,
      headers: undefined,
      hiddenHeaders: undefined,
      positionLoading: false,
      priceListPosition: null,
      tableItemsMethod: GET_PRICE_LIST_POSITION_OFFERS,
      clientPrice: 0,
      filters: []
    }
  },

  watch: {
    value() {
      this.show = this.value;

      if (this.value) {
        this.handleDialogOpen();
      }
    },

    clientPrice() {
      if (!this.positionLoading) {
        if (isNaN(this.clientPrice) || this.clientPrice < 0) {
          this.clientPrice = 0;
        }

        this.handleClientPriceChange();
      }
    }
  },

  methods: {
    fetchHeaders() {
      this.$store.dispatch(GET_PRICE_LIST_POSITION_OFFERS_TABLE_COLUMNS).then((response) => {
        this.headers = [
          {
            text: this.$t('PRICE_LISTS.LEAD'),
            value: 'is_lead',
            sortable: false,
            align: 'center',
            padding: false,
            class: 'p-0 select-column'
          },
          ...response.visible
        ];
          this.hiddenHeaders = response.hidden;
        }).catch(() => {
          this.$store.dispatch(SHOW_SNACK, {
            type: 'error',
            message: this.$t('GENERAL.UNEXPECTED_ERROR')
          });
        });
    },

    handleDialogOpen() {
      this.fetchPosition();
      this.fetchFilters();

      this.tableKey++;
    },

    fetchFilters() {
      this.$store.dispatch(GET_PRICE_LIST_POSITION_OFFERS_TABLE_FILTERS, {
        positionId: this.item.id
      })
        .then((filters) => {
          this.filters = filters;
        }).catch(() => {
          this.$store.dispatch(SHOW_SNACK, {
            type: 'error',
            message: this.$t('GENERAL.UNEXPECTED_ERROR')
          });
        });
    },

    fetchPosition() {
      this.positionLoading = true;

      this.$store.dispatch(GET_PRICE_LIST_POSITION, {
        priceListId: this.priceList.id,
        positionId: this.item.id
      })
        .then((position) => {
          this.priceListPosition = position;

          this.clientPrice = this.priceListPosition.client_price;

          this.$nextTick(() => {
            this.positionLoading = false;
          });
        }).catch(() => {
          this.$store.dispatch(SHOW_SNACK, {
            type: 'error',
            message: this.$t('GENERAL.UNEXPECTED_ERROR')
          });
        });
    },

    handleClientPriceChange: debounce(function () {
      let params = {
        positionId: this.item.id,
        clientPrice: this.clientPrice
      };

      this.$store.dispatch(UPDATE_PRICE_LIST_POSITION_CLIENT_PRICE, params)
      .then((response) => {
        this.priceListPosition.is_client_price_mannually_edited = true;

        this.$emit('client-price-change', {
          itemId: this.item.id,
          clientPrice: response.client_price_with_transport,
          fullClientPrice: response.full_client_price_with_transport,
          isClientPriceMannuallyEdited: true
        });
      }).catch(() => {
        this.positionLoading = true;

        this.$store.dispatch(SHOW_SNACK, {
          type: 'error',
          message: this.$t('GENERAL.UNEXPECTED_ERROR')
        });
      });
    }, 1500),

    handleClientPriceRefresh() {
      this.positionLoading = true;

      this.$store.dispatch(REFRESH_PRICE_LIST_POSITION_CLIENT_PRICE, this.item.id)
      .then((response) => {
        this.clientPrice = response.client_price;
        this.priceListPosition.is_client_price_mannually_edited = false;

        this.$nextTick(() => {
          this.positionLoading = false;
        });

        this.$emit('client-price-change', {
          itemId: this.item.id,
          clientPrice: response.client_price_with_transport,
          fullClientPrice: response.full_client_price_with_transport,
          isClientPriceMannuallyEdited: false
        });
      }).catch(() => {
        this.$store.dispatch(SHOW_SNACK, {
          type: 'error',
          message: this.$t('GENERAL.UNEXPECTED_ERROR')
        });
      });
    },

    handleLeadOfferChange(offer) {
      this.positionLoading = true;

      let params = {
        id: this.item.id,
        lead_offer_id: offer.id
      };

      this.$store.dispatch(UPDATE_PRICE_LIST_POSITION_LEAD_OFFER, params)
      .then((response) => {
        this.positionLoading = false;

        this.priceListPosition.transport_cost = response.transport_cost;

        this.$emit('lead-offer-change', {
          itemId: this.item.id,
          leadOffer: { ...offer, ...{ loading: response.loading, packaging: response.packaging } },
          clientPrice: response.client_price_with_transport,
          fullClientPrice: response.full_client_price_with_transport,
          transportCost: response.transport_cost,
          isCustomTransportCost: response.is_custom_transport_cost,
          lastTransportDataUpdate: response.last_transport_data_update,
          isTransportDataOutdated: response.is_transport_data_outdated
        });
      }).catch(() => {
        this.$store.dispatch(SHOW_SNACK, {
          type: 'error',
          message: this.$t('GENERAL.UNEXPECTED_ERROR')
        });
      });

      this.$refs['price-lists-dialog-table'].serverItems.map((tableOffer) => {
        if (tableOffer.id !== offer.id) {
          tableOffer.is_lead = false;
        }
      });
    },

    closeDialog(select = false) {
      this.show = false;
      this.$emit('input', false);
      this.$emit('dialog-close', { itemId: this.item.id, select: select });
    }
  }
}
</script>

<style lang="scss" scoped>

.live-list-link {
  font-size: 14px;
}
.live-list-dialog-alert {
  font-size: 14px;
  padding: 2px 6px;
  color: white;

  :deep(.v-alert__content) {
    display: flex;
    align-items: center;
  }
}

.price-input,
.price-input:focus {
  width: 30px;
  border: none;
  background-image: none;
  background-color: transparent;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  outline: none;
}

:deep(.v-alert__icon) {
  margin-right: 8px !important;
}

.filter-select {
  max-width: 200px;
}
</style>