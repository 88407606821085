<template>
  <span>
    <v-row>
      <v-col cols="12" sm="6">
        <keep-alive>
          <ContactsAutocomplete
            v-model="priceList.contact_id"
            :notRequired="true"
            clearable
            :disabled="priceList.for_group_of_contacts || inputsDisabled"
            @input="handleContactChange"
          />
        </keep-alive>
      </v-col>
      <v-col cols="12" sm="6" class="d-flex align-items-center">
        <v-switch
          v-model="priceList.for_group_of_contacts"
          class="group-of-contacts-switch"
          :disabled="inputsDisabled"
          @change="handleForGroupOfContactsChange"
        />
        {{ $t('PRICE_LISTS.FOR_GROUP_OF_CONTACTS') }}
      </v-col>
    </v-row>
    <template v-if="priceList.for_group_of_contacts">
      <InterestsForm
        :temps="temps"
        :countries="countries"
        :incoterms="incoterms"
        :priceList="priceList"
        :inputsDisabled="inputsDisabled"
        @temp-change="$emit('temp-change')"
        @price-list-updated="$emit('price-list-updated', $event)"
        @set-processing="$emit('set-processing', $event)"
      />
      <v-row>
        <v-col cols="12">
          <span class="contacts-found-label">
            {{ `${$t('GENERAL.LABELS.SELECTED')} ${priceList.contacts_count} ${$t('GENERAL.LABELS.CONTACTS')}:` }}
          </span>
          <span class="contacts-found-list text-primary" @click="showContactsDialog = true">
            {{ $t('GENERAL.LABELS.LIST_OF_THE_CONTACTS') }}
          </span>
          <ContactsDialog
            v-model="showContactsDialog"
            :server-data-options="{
              method: contactsTableItemsMethod,
              params: { priceListId: $route.params.id }
            }"
            :inputsDisabled="inputsDisabled"
            :hasSelected="priceList.contacts_count > 0"
            @contact-selection="handleContactSelection"
            @all-selected-change="handleAllContactsSelectedChange"
            @dialog-close="showContactsDialog = false"
          />
        </v-col>
      </v-row>
    </template>
  </span>
</template>

<script>
import { SHOW_SNACK } from "@/store/snack.module";
import InterestsForm from "@/views/pages/priceLists/partials/InterestsForm.vue";
import ContactsDialog from "@/views/pages/priceLists/partials/ContactsDialog.vue";
import ContactsAutocomplete from "@/views/tagProcessor/Input/ContactsAutocomplete.vue";
import {
  UPDATE_PRICE_LIST_CONTACT,
  UPDATE_PRICE_LIST_FOR_GROUP_OF_CONTACTS,
  GET_PRICE_LIST_CONTACTS,
  UPDATE_PRICE_LIST_CONTACT_SELECTION,
  UPDATE_PRICE_LIST_ALL_CONTACTS_SELECTED
} from "@/store/priceLists.module";

export default {
  components: {
    InterestsForm,
    ContactsDialog,
    ContactsAutocomplete
  },

  props: {
    temps: {
      type: Array,
      default: []
    },

    countries: {
      type: Array,
      default: []
    },

    incoterms: {
      type: Array,
      default: []
    },

    priceList: {
      type: Object,
      required: true
    },

    inputsDisabled: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      contactsTableItemsMethod: GET_PRICE_LIST_CONTACTS,
      showContactsDialog: false
    };
  },

  methods: {
    handleContactChange() {
      this.$emit('set-processing', true);

      let params = {
        id: this.$route.params.id,
        contact_id: this.priceList.contact_id
      };

      this.$store
        .dispatch(UPDATE_PRICE_LIST_CONTACT, params)
        .then(response => {
          this.$emit('price-list-updated', response);

          this.$emit('set-processing', false);
        })
        .catch(error => {
          this.$store.dispatch(SHOW_SNACK, {
            type: 'error',
            message: this.$t('GENERAL.UNEXPECTED_ERROR')
          });
        });
    },

    handleForGroupOfContactsChange() {
      this.$emit('set-processing', true);

      let params = {
        id: this.$route.params.id,
        for_group_of_contacts: this.priceList.for_group_of_contacts
      };

      this.$store
        .dispatch(UPDATE_PRICE_LIST_FOR_GROUP_OF_CONTACTS, params)
        .then(response => {
          this.$emit('price-list-updated', response);

          this.$emit('set-processing', false);
        })
        .catch(error => {
          this.$store.dispatch(SHOW_SNACK, {
            type: 'error',
            message: this.$t('GENERAL.UNEXPECTED_ERROR')
          });
        });
    },

    handleContactSelection(contactId, value) {
      this.$emit('set-processing', true);

      let params = {
        id: this.$route.params.id,
        contact_id: contactId,
        value: value
      };

      this.$store
        .dispatch(UPDATE_PRICE_LIST_CONTACT_SELECTION, params)
        .then(response => {
          this.$emit('price-list-updated', response);

          this.$emit('set-processing', false);
        })
        .catch(error => {
          this.$store.dispatch(SHOW_SNACK, {
            type: 'error',
            message: this.$t('GENERAL.UNEXPECTED_ERROR')
          });
        });
    },

    handleAllContactsSelectedChange() {
      this.$emit('set-processing', true);

      this.$store
        .dispatch(UPDATE_PRICE_LIST_ALL_CONTACTS_SELECTED, this.$route.params.id)
        .then(response => {
          this.$emit('price-list-updated', response);

          this.$emit('set-processing', false);
        })
        .catch(error => {
          this.$store.dispatch(SHOW_SNACK, {
            type: 'error',
            message: this.$t('GENERAL.UNEXPECTED_ERROR')
          });
        });
    }
  }
};
</script>

<style lang="scss" scoped>
  .contacts-found-label {
    font-size: 16px;
    font-weight: bold;
  }

  .contacts-found-list {
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
  }
</style>
