<template>
  <v-card>
    <v-row>
      <v-col cols="12" sm="6">
        <v-select
          :value="priceList.published_type?.id"
          :disabled="inputsDisabled"
          :label="$t('PRICE_LISTS.PUBLISHED_TYPE')"
          prepend-icon="mdi-share"
          :items="priceListPublishedTypes"
          item-text="name"
          item-value="key"
          @change="updatePublishedType"
        />
      </v-col>
      <v-col cols="12" sm="6">
        <DatePicker
          :label="$t('PRICE_LISTS.PUBLISHED_DATE')"
          v-model="priceList.published_date"
          :disabled="inputsDisabled"
          @input="updatePublishedDate"
        />
      </v-col>
      <v-col cols="12" sm="6">
        <v-select
          v-model="priceList.user_shares"
          chips
          small-chips
          :disabled="inputsDisabled"
          :label="$t('PRICE_LISTS.SHARE_TO_USERS')"
          :items="users"
          item-text="name"
          item-value="key"
          multiple
          @change="updateUserShares"
        >
          <template v-slot:selection="data">
            <v-chip
              small
              v-bind="data.attrs"
              :close="!inputsDisabled"
              @click:close="handleUserShareRemove(data.item.id)"
            >
              {{ data.item.name }}
            </v-chip>
          </template>
        </v-select>
      </v-col>
      <v-col cols="12" sm="6">
        <v-select
          v-model="priceList.team_shares"
          chips
          small-chips
          :disabled="inputsDisabled"
          :label="$t('PRICE_LISTS.SHARE_TO_TEAMS')"
          :items="teams"
          item-text="name"
          item-value="key"
          multiple
          @change="updateTeamShares"
        >
          <template v-slot:selection="data">
            <v-chip
              small
              v-bind="data.attrs"
              :close="!inputsDisabled"
              @click:close="handleTeamShareRemove(data.item.id)"
            >
              {{ data.item.name }}
            </v-chip>
          </template>
        </v-select>
      </v-col>
    </v-row>
    <Confirm
      v-model="publishConfirm"
      :content="$t('PRICE_LISTS.PUBLISH_CONFIRM')"
      @confirm="handlePublish"
      @decline="publishConfirm = false"
    />
  </v-card>
</template>

<script>
import { SHOW_SNACK } from "@/store/snack.module";
import Confirm from "@/views/partials/dialogs/Confirm.vue";
import DatePicker from "@/views/partials/form/DatePicker.vue";
import {
  UPDATE_PRICE_LIST_PUBLISHED_TYPE,
  UPDATE_PRICE_LIST_PUBLISHED_DATE,
  UPDATE_PRICE_LIST_USER_SHARES,
  UPDATE_PRICE_LIST_TEAM_SHARES,
  PUBLISH_PRICE_LIST
} from "@/store/priceLists.module";

export default {
  components: {
    Confirm,
    DatePicker
  },

  props: {
    priceList: {
      type: Object,
      required: true
    },

    inputsDisabled: {
      type: Boolean,
      default: false
    },

    users: {
      type: Array,
      default: () => []
    },

    teams: {
      type: Array,
      default: () => []
    },

    priceListPublishedTypes: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      publishConfirm: false
    };
  },

  methods: {
    updatePublishedType(publishedTypeId) {
      this.$emit('set-processing', true);

      let params = {
        id: this.$route.params.id,
        published_type_id: publishedTypeId
      };

      this.$store
        .dispatch(UPDATE_PRICE_LIST_PUBLISHED_TYPE, params)
        .then(response => {
          this.priceList.published_type = response.published_type;

          this.$emit('set-processing', false);
        })
        .catch(error => {
          this.$store.dispatch(SHOW_SNACK, {
            type: 'error',
            message: this.$t('GENERAL.UNEXPECTED_ERROR')
          });
        });
    },

    updatePublishedDate() {
      this.$emit('set-processing', true);

      let params = {
        id: this.$route.params.id,
        published_date: this.priceList.published_date
      };

      this.$store
        .dispatch(UPDATE_PRICE_LIST_PUBLISHED_DATE, params)
        .then(response => {
          this.$emit('set-processing', false);
        })
        .catch(error => {
          this.$store.dispatch(SHOW_SNACK, {
            type: 'error',
            message: this.$t('GENERAL.UNEXPECTED_ERROR')
          });
        });
    },

    updateUserShares() {
      this.$emit('set-processing', true);

      let params = {
        id: this.$route.params.id,
        users: this.priceList.user_shares
      };

      this.$store
        .dispatch(UPDATE_PRICE_LIST_USER_SHARES, params)
        .then(response => {
          this.$emit('set-processing', false);
        })
        .catch(error => {
          this.$store.dispatch(SHOW_SNACK, {
            type: 'error',
            message: this.$t('GENERAL.UNEXPECTED_ERROR')
          });
        });
    },

    handleUserShareRemove(userId) {
      this.priceList.user_shares = this.priceList.user_shares.filter(
        id => id !== userId
      );

      this.updateUserShares();
    },

    updateTeamShares() {
      this.$emit('set-processing', true);

      let params = {
        id: this.$route.params.id,
        teams: this.priceList.team_shares
      };

      this.$store
        .dispatch(UPDATE_PRICE_LIST_TEAM_SHARES, params)
        .then(response => {
          this.$emit('set-processing', false);
        })
        .catch(error => {
          this.$store.dispatch(SHOW_SNACK, {
            type: 'error',
            message: this.$t('GENERAL.UNEXPECTED_ERROR')
          });
        });
    },

    handleTeamShareRemove(teamId) {
      this.priceList.team_shares = this.priceList.team_shares.filter(
        id => id !== teamId
      );

      this.updateTeamShares();
    },

    publish() {
      this.publishConfirm = true;
    },

    handlePublish() {
      this.$emit('set-processing', true);

      this.$store
        .dispatch(PUBLISH_PRICE_LIST, this.$route.params.id)
        .then(response => {
          this.priceList.status = response.status;

          this.$emit('set-processing', false);

          this.$router.push({
            name: 'price-lists'
          });
        })
        .catch(error => {
          let message = error.message ? error.message : this.$t('GENERAL.UNEXPECTED_ERROR');

          this.$store.dispatch(SHOW_SNACK, {
            type: 'error',
            message: message
          });

          this.$emit('set-processing', false);
        });
    }
  }
};
</script>

<style lang="scss" scoped>
  //
</style>
