<template>
  <v-card :disabled="isProcessing" :loading="isProcessing">
    <template v-slot:progress>
      <v-progress-linear
        absolute
        color="success"
        height="4"
        indeterminate
      ></v-progress-linear>
    </template>
    <v-toolbar
      color="#c31100"
      dark
    >
      <v-toolbar-title>{{ $route.params.id ? $t("PRICE_LISTS.EDIT") : $t("PRICE_LISTS.CREATE") }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-tooltip v-if="$route.params.id && step == 2" left color="warning">
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            v-bind="attrs"
            v-on="on"
            :disabled="inputsDisabled || isProcessing"
            @click="handleRefreshClick"
          >
            mdi-refresh
          </v-icon>
        </template>
        <span>
          {{ $t('GENERAL.LABELS.REFRESH_DATA') }}
        </span>
      </v-tooltip>
    </v-toolbar>
    <v-card flat>
      <v-stepper v-model="step" class="stepper">
        <v-stepper-header>
          <v-stepper-step
            step="1"
            class="set-cursor-pointer"
            @click="changeStep(1)"
          >
            {{ $t("PRICE_LISTS.STEP_1") }}
          </v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step
            step="2"
            class="set-cursor-pointer"
            @click="changeStep(2)"
          >
            {{ $t("PRICE_LISTS.STEP_2") }}
          </v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step
            step="3"
            class="set-cursor-pointer"
            @click="changeStep(3)"
          >
            {{ $t("PRICE_LISTS.STEP_3") }}
          </v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step
            step="4"
            class="set-cursor-pointer"
            @click="changeStep(4)"
          >
            {{ $t("PRICE_LISTS.STEP_4") }}
          </v-stepper-step>
        </v-stepper-header>
        <v-stepper-items>
          <v-stepper-content step="1">
            <BaseStep
              v-if="!$route.params.id || priceList.id"
              :temps="temps"
              :countries="countries"
              :incoterms="incoterms"
              :priceListTypes="priceListTypes"
              :priceListDateTypes="priceListDateTypes"
              :priceList="priceList"
              :inputsDisabled="inputsDisabled"
              @price-list-created="priceList = $event"
              @price-list-updated="
                priceList = $event;
                needPriceListPositionsFetch = true;
              "
              @temp-change="handleTempChange"
              @set-processing="setProcessing"
            />
          </v-stepper-content>
          <v-stepper-content step="2" class="p-0">
            <PriceListStep
              v-if="priceList.id"
              ref="priceListStep"
              :temps="temps"
              :currencies="currencies"
              :countries="countries"
              :priceList="priceList"
              :inputsDisabled="inputsDisabled"
              @temp-change="handleTempChange"
              @currency-change="handleCurrencyChange"
              @include-transport-cost-change="handleIncludeTransportCostChange"
              @destination-country-change="handleDestinationCountryChange"
              @set-processing="setProcessing"
              @positions-updated="priceListPositions = $event"
              @positions-updated-need-contacts-fetch="
                priceListPositions = $event;
                needContactsCountFetch = true;
              "
            />
          </v-stepper-content>
          <v-stepper-content step="3">
            <PreviewStep
              v-if="priceList.id"
              ref="previewStep"
              :priceList="priceList"
              :priceListPositions="priceListPositions"
              :inputsDisabled="inputsDisabled"
              @set-processing="setProcessing"
            />
          </v-stepper-content>
          <v-stepper-content step="4">
            <PublishStep
              v-if="priceList.id"
              ref="publishStep"
              :users="users"
              :teams="teams"
              :priceListPublishedTypes="priceListPublishedTypes"
              :priceList="priceList"
              :inputsDisabled="inputsDisabled"
              @set-processing="setProcessing"
            />
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
      <v-card-actions :class="{ 'card-actions-processing': isProcessing }">
        <v-spacer></v-spacer>
        <v-btn
          v-if="step > 1"
          color="warning"
          @click="changeStep(step - 1)"
        >
          {{ $t('GENERAL.LABELS.BACK_BUTTON') }}
        </v-btn>
        <template v-if="$route.params.id && step === 3">
          <HtmlEditor :getContent="getPrintViewContent"></HtmlEditor>
          <v-btn
            color="success"
            @click="download"
          >
            {{ $t('GENERAL.LABELS.DOWNLOAD_BUTTON') }}
          </v-btn>
        </template>
        <v-btn
          v-if="$route.params.id && step === 4 && priceList.status?.slug !== 'published'"
          color="primary"
          :disabled="inputsDisabled || isProcessing"
          @click="publish"
        >
          {{ $t('PRICE_LISTS.PUBLISH_BUTTON') }}
        </v-btn>
        <v-btn
          v-if="$route.params.id && step < 4"
          color="primary"
          @click="changeStep(step + 1)"
        >
          {{ $t('GENERAL.LABELS.CONTINUE_BUTTON') }}
        </v-btn>
      </v-card-actions>
    </v-card>
    <RefreshDialog
      v-model="refreshDialog"
      :changes="refreshChanges"
      :inputsDisabled="inputsDisabled"
      @refresh-approved="handleRefreshData"
      @dialog-close="refreshDialog = false"
    />
  </v-card>
</template>

<script>
import { SHOW_SNACK } from "@/store/snack.module";
import { GET_SELECTS_DATA } from "@/store/selectsData.module";
import HtmlEditor from "@/views/partials/content/HtmlEditor.vue";
import BaseStep from "@/views/pages/priceLists/partials/BaseStep.vue";
import PriceListStep from "@/views/pages/priceLists/partials/PriceListStep.vue";
import PreviewStep from "@/views/pages/priceLists/partials/PreviewStep.vue";
import PublishStep from "@/views/pages/priceLists/partials/PublishStep.vue";
import RefreshDialog from "@/views/pages/priceLists/partials/RefreshDialog.vue";
import {
  GET_PRICE_LIST,
  UPDATE_PRICE_LIST_TEMP,
  UPDATE_PRICE_LIST_CURRENCY,
  UPDATE_PRICE_LIST_INCLUDE_TRANSPORT_COST,
  UPDATE_PRICE_LIST_DESTINATION_COUNTRY,
  CREATE_PRICE_LIST_REFRESH_REQUEST,
  REFRESH_PRICE_LIST_SNAPSHOT
} from "@/store/priceLists.module";

export default {
  components: {
    BaseStep,
    PriceListStep,
    PreviewStep,
    PublishStep,
    HtmlEditor,
    RefreshDialog
  },

  data() {
    return {
      step: 1,
      priceList: {
        name: '',
        type: {
          id: null,
          name: '',
          slug: ''
        },
        date_type: {
          id: null,
          name: '',
          slug: ''
        },
      },
      users: [],
      teams: [],
      temps: [],
      currencies: [],
      countries: [],
      incoterms: [],
      priceListTypes: [],
      priceListDateTypes: [],
      priceListPublishedTypes: [],
      priceListPositions: [],
      isPriceListPositionsTableHeadersFetched: false,
      needPriceListPositionsFetch: true,
      needContactsCountFetch: true,
      refreshDialog: false,
      refreshChanges: {},
      isProcessing: false
    };
  },

  computed: {
    inputsDisabled() {
      return (this.$route.params.id && !this.hasPermissions('price-lists-edit')) ||
        this.isProcessing ||
        this.priceList.status?.slug === 'published';
    }
  },

  watch: {
    step() {
      if ((this.step === 2 || this.step === 3) && this.needPriceListPositionsFetch) {
        this.$refs.priceListStep.fetchItems();
        this.$refs.priceListStep.fetchFilters();
        this.needPriceListPositionsFetch = false;

        if (!this.isPriceListPositionsTableHeadersFetched) {
          this.$refs.priceListStep.fetchHeaders();
          this.$refs.priceListStep.$refs.priceListItemDialog.fetchHeaders();
          this.isPriceListPositionsTableHeadersFetched = true;
        }
      }

      if (this.step === 3 && this.needContactsCountFetch) {
        this.$refs.previewStep.fetchContactsCount();
        this.needContactsCountFetch = false;
      }
    }
  },

  mounted() {
    this.fetchSelectsData();

    if (this.$route.params.id) {
      this.fetchPriceList();
    }
  },

  methods: {
    fetchSelectsData() {
      return this.$store.dispatch(GET_SELECTS_DATA, [
        'users',
        'teams',
        'temps',
        'currencies-with-rates',
        'countries',
        'incoterms',
        'price-list-types',
        'price-list-date-types',
        'price-published-types'
      ])
        .then((response) => {
          this.users = response['users'];
          this.teams = response['teams'];
          this.temps = response['temps'];
          this.currencies = response['currencies-with-rates'];
          this.countries = response['countries'];
          this.incoterms = response['incoterms'];
          this.priceListTypes = response['price-list-types'];
          this.priceListDateTypes = response['price-list-date-types'];
          this.priceListPublishedTypes = response['price-published-types'];
        }).catch(() => {
          this.$store.dispatch(SHOW_SNACK, {
            type: 'error',
            message: this.$t('GENERAL.UNEXPECTED_ERROR')
          });
        });
    },

    fetchPriceList() {
      this.isProcessing = true;
      this.$store
        .dispatch(GET_PRICE_LIST, this.$route.params.id)
        .then(response => {
          this.priceList = response;

          this.isProcessing = false;
        })
        .catch(() => {
          this.$store.dispatch(SHOW_SNACK, {
            type: 'error',
            message: this.$t('GENERAL.UNEXPECTED_ERROR')
          });
        });
    },

    handleTempChange() {
      this.isProcessing = true;

      let params = {
        id: this.$route.params.id,
        temp_mapping_value_id: this.priceList.temp_mapping_value_id
      };

      this.$store
        .dispatch(UPDATE_PRICE_LIST_TEMP, params)
        .then(response => {
          this.priceList = response;

          if (this.step === 2) {
            this.$refs.priceListStep.fetchItems();
            this.$refs.priceListStep.fetchFilters();
          } else {
            this.needPriceListPositionsFetch = true;
          }

          this.isProcessing = false;
        })
        .catch(error => {
          this.$store.dispatch(SHOW_SNACK, {
            type: 'error',
            message: this.$t('GENERAL.UNEXPECTED_ERROR')
          });
        });
    },

    handleCurrencyChange() {
      this.isProcessing = true;

      let params = {
        id: this.$route.params.id,
        currency_id: this.priceList.currency.id
      };

      this.$store
        .dispatch(UPDATE_PRICE_LIST_CURRENCY, params)
        .then(response => {
          this.priceList = response;

          this.isProcessing = false;
        })
        .catch(error => {
          this.$store.dispatch(SHOW_SNACK, {
            type: 'error',
            message: this.$t('GENERAL.UNEXPECTED_ERROR')
          });
        });
    },

    handleIncludeTransportCostChange() {
      this.isProcessing = true;

      let params = {
        id: this.$route.params.id,
        include_transport_cost: this.priceList.include_transport_cost
      };

      this.$store
        .dispatch(UPDATE_PRICE_LIST_INCLUDE_TRANSPORT_COST, params)
        .then(response => {
          this.priceList = response;

          if (!this.priceList.include_transport_cost) {
            if (this.step === 2) {
              this.$refs.priceListStep.fetchItems();
            } else {
              this.needPriceListPositionsFetch = true;
            }
          }

          this.isProcessing = false;
        })
        .catch(error => {
          this.$store.dispatch(SHOW_SNACK, {
            type: 'error',
            message: this.$t('GENERAL.UNEXPECTED_ERROR')
          });
        });
    },

    handleDestinationCountryChange() {
      this.isProcessing = true;

      let params = {
        id: this.$route.params.id,
        destination_country_id: this.priceList.destination_country_id
      };

      this.$store
        .dispatch(UPDATE_PRICE_LIST_DESTINATION_COUNTRY, params)
        .then(response => {
          this.priceList = response;

          if (this.step === 2) {
            this.$refs.priceListStep.fetchItems();
          } else {
            this.needPriceListPositionsFetch = true;
          }

          this.isProcessing = false;
        })
        .catch(error => {
          this.$store.dispatch(SHOW_SNACK, {
            type: 'error',
            message: this.$t('GENERAL.UNEXPECTED_ERROR')
          });
        });
    },

    handleRefreshClick() {
      this.isProcessing = true;

      this.$store.dispatch(CREATE_PRICE_LIST_REFRESH_REQUEST, this.$route.params.id)
        .then(response => {
          this.refreshChanges = response;

          this.refreshDialog = true;

          this.isProcessing = false;
        })
        .catch(error => {
          this.$store.dispatch(SHOW_SNACK, {
            type: 'error',
            message: this.$t('GENERAL.UNEXPECTED_ERROR')
          });
        })
    },

    handleRefreshData() {
      this.isProcessing = true;

      let params = {
        priceListId: this.$route.params.id,
        refreshRequestId: this.refreshChanges.refresh_request_id
      };

      this.$store.dispatch(REFRESH_PRICE_LIST_SNAPSHOT, params)
        .then(() => {
          this.$refs.priceListStep.fetchItems().then(() => {
            this.isProcessing = false;
          });
          this.$refs.priceListStep.fetchFilters();
          this.refreshDialog = false;
        })
        .catch(error => {
          this.isProcessing = false;
          this.refreshDialog = false;

          this.$store.dispatch(SHOW_SNACK, {
            type: 'error',
            message: this.$t('GENERAL.UNEXPECTED_ERROR')
          });
        })
    },

    getPrintViewContent() {
      return this.$refs.previewStep.getPrintViewContent();
    },

    download() {
      this.$refs.previewStep.download();
    },

    publish() {
      this.$refs.publishStep.publish();
    },

    changeStep(step) {
      if (!this.$route.params.id) {
        return;
      }

      if (
        this.priceList.type?.slug === 'offer-to-a-contact-or-a-group-of-contacts' &&
        this.priceList.incoterm?.include_transport &&
        !this.priceList.incoterm_destination_country_id
      ) {
        return;
      }

      this.step = step;
    },

    setProcessing(value) {
      this.isProcessing = value;
    }
  }
};
</script>

<style lang="scss" scoped>
  .stepper {
    box-shadow: none !important;
  }

  .card-actions-processing {
    min-height: 92px !important;
  }
</style>
